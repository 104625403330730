import { driverConstants } from '../constants';

export const setDrivers = (drivers) => {
  return {
    type: driverConstants.SET_DRIVERS,
    drivers,
  };
};

export const setCurrentDriver = (driver) => {
  return {
    type: driverConstants.SET_CURRENT_DRIVER,
    driver,
  };
};
