const Fetch = (url, method, body) => {
  
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  
  const token = localStorage.getItem('token');

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    };
  }

  return fetch(url, {
    headers: headers,
    body: method !== 'GET' ? JSON.stringify(body) : undefined,
    method,
  });
};

export default Fetch;
