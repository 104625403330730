import './navs.css';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Image,
  Dropdown,
} from 'react-bootstrap';
import {
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaHistory,
  FaUser,
  FaTachometerAlt,
  FaSignOutAlt,

} from 'react-icons/fa';
import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/avatar.png';
import { logout } from '../../redux/actions';
import { useDispatch } from 'react-redux';

function Header({ navColor = 'white', isFixed = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let itemsColor = 'whiteColor';
  const whiteNav = navColor === 'white';
  itemsColor = whiteNav ? 'primaryColor' : 'whiteColor';

  const user = JSON.parse(localStorage.getItem('currentUser'));
  // const user = { avatar: '', name: 'Herve N' };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate('/login');
  }
  
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed={isFixed ? 'top' : undefined}
      variant="primary"
      className="header"
      style={{
        backgroundColor: navColor,
        transition: 'all 0.2s',
        boxShadow: whiteNav ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 'none',
        width: '100%',
      }}
    >
      <Container className="header-container">
        <Navbar.Brand>
          {/* <Link to="/">
            <img src={logo} alt="" className="logo" />
          </Link> */}
          <a href="https://www.tuzaride.com">
            <img src={logo} alt="" className="logo" />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto align-items-center">
            {/* <Link to="/" className={`links ${itemsColor}`}>
              Home
            </Link> */}
            {/* <Link to="/hire-a-driver" className={`links ${itemsColor}`}>
              Hire a driver
            </Link> */}
            <Link to="/about" className={`links ${itemsColor}`}>
              About us
            </Link>
            <Link to="/our-customers" className={`links ${itemsColor}`}>
              Our customers
            </Link>
            <Link to="/contact-us" className={`links ${itemsColor}`}>
              Contact us
            </Link>
            {user ? 
            (
              <Nav className="ml-auto">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-basic"
                    className={`align-items-center d-flex ${itemsColor}`}
                    style={{ color: 'transparent' }}
                  >
                    <Image
                      src={user.avatar || avatar}
                      roundedCircle
                      className="mr-2 avatarImg"
                    />
                    <span className={`links ${itemsColor}`}>
                      {user.name || ''}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="nav-dropdown-menu">
                    {user.role === 'admin' && (
                      <Dropdown.Item href="/admin"><FaTachometerAlt/> Dashboard</Dropdown.Item>
                    )}
                    <Dropdown.Item href="/history"><FaHistory/> History</Dropdown.Item>
                    <Dropdown.Item href="/profile"><FaUser/> Profile</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleLogout}><FaSignOutAlt/> Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            ) 
            : 
            (
              <Link to="/login" className={`links ${itemsColor}`}>
                
              </Link>
            )
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function Footer() {
  return (
    <footer className="footer page-footer font-small blue">
      <Container className="footer-container">
        <Row>
          <Col md={4}>
            <Navbar.Brand href="/">
              <Link to="/">
                <img src={logo} alt="" className="logo" />
              </Link>
            </Navbar.Brand>
            <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-md-2 mt-0 mt-sm-0">
              <li className="ftco-animate fadeInUp ftco-animated">
                <a
                  href="https://wa.me/250791700817"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp />
                </a>
              </li>
              <li className="ftco-animate fadeInUp ftco-animated">
                <a href="/#" target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
              </li>
              <li className="ftco-animate fadeInUp ftco-animated">
                <a href="/#" target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
              </li>
              <li className="ftco-animate fadeInUp ftco-animated">
                <a
                  href="https://www.instagram.com/tuzaride_/?igshid=MGU3ZTQzNzY%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li className="ftco-animate fadeInUp ftco-animated">
                <a
                  href="https://www.linkedin.com/company/mbgates"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h2>
              <Nav.Link href="#">Information</Nav.Link>
            </h2>
            <ul className="list-unstyled">
              <li>
                <Link to="/terms">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <Link to="#">Payment options</Link>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h2>
              <Nav.Link href="#">Have A Question?</Nav.Link>
            </h2>
            <ul className="list-unstyled">
              <li>
                <Link
                  href="https://wa.me/250791700817"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Whatsapp: +250791900848
                </Link>
              </li>
              <li>
                <Link to="#!">Email: info@tuzaride.com</Link>
              </li>
              <li>
                <Link to="#!">Phone number: +250791900848</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="footer-copyright text-center pt-5">
        © 2023 Copyright <Link to="https://tuzaride.com/">TUZAride.com</Link>
      </div>
    </footer>
  );
}

export { Header, Footer };
