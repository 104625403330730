import React from 'react';
import { Navigate } from 'react-router-dom';

const token = localStorage.getItem('token');

const isLoggedIn = token !== null;
function Protected({ children }) {
  return isLoggedIn ? children : <Navigate to="/login" replace />;
}

export default Protected;