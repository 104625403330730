import React from "react";
import './DriverForm.css'


import  { useState, useEffect } from 'react';
import {  Alert, Card, Table,Container } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';



const DriverForm = (props) => {
    const [stickers, setStickers] = useState([]);
    useEffect(() => {
      const newStickers = [];
      for (let i = 0; i < 30; i++) {
        newStickers.push({
          id: i,
          left: `${Math.random() * 100}vw`,
          animationDelay: `${Math.random() * 2}s`
        });
      }
      setStickers(newStickers);
    
      const timeoutId = setTimeout(() => {
        setStickers([]);
      }, 4000);
    
      return () => clearTimeout(timeoutId);
    }, []);
  
    return (
      <Container className="driverForm-container DriverForm">
        {stickers.map(sticker => (
          <div
            key={sticker.id}
            className="sticker"
            style={{ left: sticker.left, animationDelay: sticker.animationDelay }}
          >
            👏
          </div>
        ))}
        <Alert variant="success" className="app-alert">
          Successful paid
        </Alert>
        <div className='app-card-container' >
        
        <Card className="app-card" >
          <Card.Header className="card-header">Driver Arriving in few Minutes</Card.Header>
          <Card.Body>
            <div className="user-info">
              <img className="user-image" src={props.props.driver.image} alt="Driver" />
              <h3 className="user-name">{props.props.driver.name}</h3>
            </div>
            <Table responsive className="info-table">
              <tbody>
                <tr>
                  <td>Pick up location:</td>
                  <td>{props.props.pickupLocation}</td>
                </tr>
                <tr>
                  <td>Destination location:</td>
                  <td>{props.props.destination}</td>
                </tr>
                <tr>
                  <td>Cost:</td>
                  <td>{props.props.price}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        </div>
      </Container>
    );
  };


  export default DriverForm