export const handlePrice = (distance) => {
  let costInRwf = 0;
  if (distance) {
    const numberString = distance.split(' ')[0];
    const kilometres = parseFloat(numberString.replaceAll(',', ''));
    if (kilometres > 10 && kilometres <= 30) {
      costInRwf = 12000;
    } else if (kilometres > 30 && kilometres <= 60) {
      costInRwf = 15000;
    } else if (kilometres > 60 && kilometres <= 100) {
      costInRwf = 20000;
    } else if (kilometres > 100 && kilometres <= 200) {
      costInRwf = 30000;
    } else if (kilometres > 200 && kilometres <= 500) {
      costInRwf = 35000;
    } else if (kilometres > 500) {
      costInRwf = 40000;
    } else {
      const roundedKilometres = (Math.round(kilometres * 10) / 10).toFixed(1);
      const firstKilometerCost = 1200;
      const restKilometersCost =
        roundedKilometres > 1 ? (roundedKilometres - 1) * 800 : 0;
      const totalCost = firstKilometerCost + restKilometersCost;
      costInRwf = totalCost;
    }
  }
  return costInRwf;
};

export const isNum = (val) => /^\d+$/.test(val);
