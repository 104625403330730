/* eslint-disable no-unused-vars */
import { userConstants } from '../constants';
import { removeUserFromLocalStorage } from '../../services';
// import { alertActions } from '.';
// import { history } from '../../helpers';

export const setCurrentUser = (currentUser) => {
  return {
    type: userConstants.LOGIN_SUCCESS,
    currentUser,
  };
};

export const logout = () => {
  removeUserFromLocalStorage();
  return { type: userConstants.LOGOUT };
}

// function login(username, password) {
//   function request(user) {
//     return { type: userConstants.LOGIN_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.LOGIN_SUCCESS, user };
//   }
//   function failure(error) {
//     return { type: userConstants.LOGIN_FAILURE, error };
//   }

//   return (dispatch) => {
//     dispatch(request({ username }));

//     userService.login(username, password).then(
//       (user) => {
//         dispatch(success(user));
//         history.push('/');
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };
// }

// function register(user) {
//   function request(newUser) {
//     return { type: userConstants.REGISTER_REQUEST, newUser };
//   }
//   function success(authUser) {
//     return { type: userConstants.REGISTER_SUCCESS, authUser };
//   }
//   function failure(error) {
//     return { type: userConstants.REGISTER_FAILURE, error };
//   }

//   return (dispatch) => {
//     dispatch(request(user));

//     userService.register(user).then(
//       (newUser) => {
//         dispatch(success());
//         history.push('/login');
//         dispatch(alertActions.success('Registration successful'));
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };
// }

// function getAll() {
//   function request() {
//     return { type: userConstants.GETALL_REQUEST };
//   }
//   function success(users) {
//     return { type: userConstants.GETALL_SUCCESS, users };
//   }
//   function failure(error) {
//     return { type: userConstants.GETALL_FAILURE, error };
//   }

//   return (dispatch) => {
//     dispatch(request());

//     userService.getAll().then(
//       (users) => dispatch(success(users)),
//       (error) => dispatch(failure(error.toString()))
//     );
//   };
// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function deleteUser(id) {
//   function request(userId) {
//     return { type: userConstants.DELETE_REQUEST, userId };
//   }
//   function success(userId) {
//     return { type: userConstants.DELETE_SUCCESS, userId };
//   }
//   function failure(userId, error) {
//     return { type: userConstants.DELETE_FAILURE, userId, error };
//   }

//   return (dispatch) => {
//     dispatch(request(id));

//     userService.delete(id).then(
//       (user) => dispatch(success(id)),
//       (error) => dispatch(failure(id, error.toString()))
//     );
//   };
// }

// export const userActions = {
//   login,
//   logout,
//   register,
//   getAll,
//   delete: deleteUser,
// };
