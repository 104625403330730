/* eslint-disable no-underscore-dangle */
import './admin.css';
import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { format } from 'date-fns';
import { FaSearch, FaCogs, FaForward } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useSelector, useDispatch } from 'react-redux';
import Fuse from 'fuse.js';
import { DriversForm } from '../../components/Forms';
import { ModifyDrivers } from '../../components/Modal';

import { FaArrowLeft, FaArrowRight, FaBackward, FaTrash,FaToggleOn, FaToggleOff } from 'react-icons/fa';

import {
  getSessions,
  manageSession,
  updateSession,
  getDrivers,
  deleteSession,
  updateDrivers,
} from '../../services';
import { setBookings, setDrivers, setCurrentDriver, deleteBooking } from '../../redux/actions';
import { isNum } from '../../utils';
import { Dropdown, Modal } from 'react-bootstrap';
import VisualRepresentation from './visualRepresentation';
import SharedNumber from './SharedNumber';
import Message from './Message';

function DetailView({ client, onBack }) {
  return (
    <>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>
              <strong>Client's Phone number:</strong>
            </td>
            <td>{client.phoneNumber}</td>
          </tr>
          <tr>
            <td>
              <strong>Driver:</strong>
            </td>
            <td>
              {client.driver ? (
                <>
                  <img
                    src={client.driver.image || ''}
                    alt={client.driver.name || ''}
                    className="driverImg"
                  />
                  {client.driver.name}
                </>
              ) : (
                'N/A'
              )}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Trip type:</strong>
            </td>
            <td>{client.tripType}</td>
          </tr>
          <tr>
            <td>
              <strong>Created at:</strong>
            </td>
            <td>
              {client.createdAt
                ? format(new Date(client.createdAt), 'MMM dd, yyyy hh:mm a')
                : ''}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Distance:</strong>
            </td>
            <td>{client.distance}</td>
          </tr>
        </tbody>
      </Table>

      <Button className="custom-btn" onClick={onBack}>
        Back
      </Button>
    </>
  );
}

function BookingsTab() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const { bookings } = useSelector((state) => state.booking);
  const dispatch = useDispatch();
  const [searchQuery, setSearch] = useState('');
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [view, setView] = useState('bookings');
  const options = {
    keys: ['pickupLocation', 'clientNames', 'destination'],
  };

  useEffect(() => {
    getSessions().then((res) => {
      if (res.status === 200) {
        dispatch(setBookings(res.data));
      }
    });
  }, [openErrorAlert, openSuccessAlert]);

  useEffect(() => {
    getSessions().then((res) => {
      if (res.status === 200) {
        const paidBookings = res.data.filter((booking) => booking.hasPaid);
        dispatch(setBookings(paidBookings));
      }
    });
  }, []);

  // Define the columns for the table
  const columns = React.useMemo(() => {
    return [
      {
        Header: '#',
        accessor: (row, index) => index + 1,
      },
      {
        Header: 'Names',
        accessor: 'clientNames',
      },
      {
        Header: 'Pick up',
        accessor: 'pickupLocation',
      },
      {
        Header: 'Destination',
        accessor: 'destination',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const costInRwf = row.original.price;
          return isNum(costInRwf)
            ? `${parseFloat(costInRwf).toLocaleString()} Rwf`
            : costInRwf;
        },
      },
      {
        Header: 'Manage',
        accessor: '_id', // Use the booking ID as the accessor
        Cell: ({ row }) => (
          <>
            <Button
              className="btn-sm btn-confirm"
              disabled={row.original.hasPaid}
              onClick={() => {
                if (!row.original.hasPaid) {
                  manageSession(row.original._id, { hasPaid: true })
                    .then((res) => {
                      if (res.status === 200) {
                        setOpenSuccessAlert(true);
                      } else {
                        setOpenErrorAlert(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                      setOpenErrorAlert(true);
                    });
                }
              }}
              style={{
                backgroundColor: row.original.hasPaid ? 'gray' : undefined,
              }}
            >
              {row.original.hasPaid ? 'Confirmed' : 'Confirm'}
            </Button>
            <Button
              className="btn-sm"
              variant="success"
              disabled={row.original.status === 'Complete'}
              onClick={() => {
                updateSession(row.original._id, { status: 'Complete' })
                  .then((res) => {
                    if (res.status === 200) {
                      setOpenSuccessAlert(true);
                    } else {
                      setOpenErrorAlert(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    setOpenErrorAlert(true);
                  });
              }}
            >
              Completed
            </Button>
          </>
        ),
      },

      {
        Header: 'Status',
        accessor: 'hasPaid',
        Cell: ({ value }) =>
          value ? (
            <Button className="btn-sm" variant="success" disabled>
              Paid
            </Button>
          ) : (
            <Button className="btn-sm" variant="danger" disabled>
              Unpaid
            </Button>
          ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
            ></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setSelectedClient(row.original);
                  console.log('row original:', row.original);
                  setView('detail');
                }}
              >
                <FaArrowRight /> More
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  const token = localStorage.getItem('token');
                  deleteSession(row.original._id, token)
                    .then((res) => {
                      if (res.status === 200) {
                        setModalMessage('Successfully deleted');
                        setModalIsOpen(true);
                        dispatch(deleteBooking(row.original._id));
                      } else {
                        setModalMessage('Not deleted successfully');
                        setModalIsOpen(true);
                      }
                    })
                    .catch((err) => {
                      setModalMessage(`An error occurred: ${err.message}`);
                      setModalIsOpen(true);
                    });
                }}
              >
                <FaTrash /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: bookings,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    usePagination
  );

  if (view === 'detail' && selectedClient) {
    return (
      <DetailView client={selectedClient} onBack={() => setView('bookings')} />
    );
  }

  return (
    <>
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Session Delete Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Alert
        dismissible
        variant="danger"
        show={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        Something went wrong.
      </Alert>
      <Alert
        dismissible
        variant="success"
        show={openSuccessAlert}
        onClose={() => setOpenSuccessAlert(false)}
      >
        Success.
      </Alert>
      <Form className="admin-search-form">
        <Row>
          <Col>
            <Form.Control
              style={{ width: '30vw' }}
              placeholder="Search ..."
              value={searchQuery}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Form.Text>Search by name or location</Form.Text>
          </Col>
          <Col>
            <Button
              style={{ width: '6vw' }}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                const fuse = new Fuse(bookings, options);
                const result = fuse.search(searchQuery);
                const editedResult = result.map((el) => el.item);
                dispatch(setBookings(editedResult));
              }}
            >
              <FaSearch />
            </Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination-container">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {<FaBackward />}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {<FaArrowLeft />}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {<FaArrowRight />}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {<FaForward />}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span>
          | Go to page:
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 15, 20, 25, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

function ManageDriversTab() {
  const { drivers } = useSelector((state) => state.drivers);
  const dispatch = useDispatch();
  const [searchQuery, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const [componentReload, setComponentReload] = useState(true)
  const options = {
    keys: ['name', 'bio'],
  };

  useEffect(() => {
    getDrivers().then((res) => {
      if (res.status === 200) {
        dispatch(setDrivers(res.data));
      }
    });
  }, [componentReload]);

  const handleAvailabilityToggle = async (driverId, isAvailable) => {
    const updatedDriver = await updateDrivers({ isAvailable: !isAvailable }, driverId);
    setComponentReload((prev)=>{
      return !prev
    })
  };
 

  return (
    <>
      <Form className="admin-search-form">
        <Row>
          <Col>
            <Form.Control
              onChange={(e) => setSearch(e.target.value)}
              value={searchQuery}
              style={{ width: '30vw' }}
              placeholder="Search ..."
            />
            <Form.Text>Search by name or bio details</Form.Text>
          </Col>
          <Col>
            <Button
              style={{ width: '6vw' }}
              onClick={(e) => {
                e.preventDefault();
                const fuse = new Fuse(drivers, options);
                const result = fuse.search(searchQuery);
                const editedResult = result.map((el) => el.item);
                console.table(editedResult);
                dispatch(setDrivers(editedResult));
              }}
            >
              <FaSearch />
            </Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Names</th>
            <th>Bio</th>
            <th>Phone number</th>
            <th>Availability</th>
            <th>Manage</th>
          </tr>
        </thead>
        {drivers && drivers.length > 0 && (
          <tbody>
            {drivers.map((el, index) => (
              <tr key={el._id}>
                <td>{index + 1}</td>
                <td>{el.name}</td>
                <td>{el.bio}</td>
                <td>{el.phoneNumber}</td>
                <td>
                <button
  className={`availability-toggle ${el.isAvailable ? 'active' : ''}`}
  onClick={() => handleAvailabilityToggle(el._id, el.isAvailable)}
>
  <div className="toggle-container">
    <div className={`toggle-track ${el.isAvailable ? 'active' : ''}`}>
      <div className={`toggle-thumb ${el.isAvailable ? 'active' : ''}`}>
        {el.isAvailable ? <FaToggleOn className="toggle-icon" /> : <FaToggleOff className="toggle-icon" />}
      </div>
    </div>
  </div>
</button>

            </td>
                <td>
                  <Button
                    onClick={() => {
                      dispatch(setCurrentDriver(el._id));
                      setShow(true);
                    }}
                  >
                    Manage
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <ModifyDrivers show={show} setShow={setShow} />
    </>
  );
}

function Admin() {
  return (
    <div className="admin-base">
      <Container className="admin-body">
        <span className="sub-header">
          {' '}
          <FaCogs /> Admin dashboard
        </span>
        <Tabs defaultActiveKey="bookings" style={{ marginTop: '3vh' }}>
          <Tab
            eventKey="bookings"
            title="Bookings"
            style={{ marginBottom: '5vh', marginTop: '5vh' }}
          >
            {' '}
            <BookingsTab />{' '}
          </Tab>
          <Tab
            eventKey="manageDriver"
            title="Drivers"
            style={{ marginBottom: '15vh' }}
          >
            {' '}
            <ManageDriversTab />{' '}
          </Tab>
          <Tab eventKey="AddingAdriver" title="Adding driver">
            {' '}
            <DriversForm action="add" />
          </Tab>
          <Tab eventKey="dashboardVisualization" title="dashboard" >
            {' '}
            <VisualRepresentation />
          </Tab>
          <Tab eventKey="numberShared" title="share times per user" style={{ marginTop: '2rem', marginBottom: '3rem'}}>
            {' '}
            <SharedNumber />
          </Tab>
          <Tab eventKey="MessageOfTheDay" title="Message of the day" style={{ marginTop: '2rem', marginBottom: '3rem'}}>
            {' '}
            <Message/>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default Admin;
