import './about.css';
import React from 'react';
import Container from 'react-bootstrap/Container';

function About() {
  return (
    <div className="about-base">
      <Container className="about-body">
        <span className="about-title">About</span>
        We started TUZAride to provide you with a superior chauffeur/driver
        hailing experience that is secure, saves you time, money, and energy,
        allowing you to focus on the things you love! Our goal is to make
        available to you a wide variety of qualified drivers at the best prices,
        enabling you to experience great service. Once your request has been
        received, we make sure our drivers attend to you as soon as possible.
        TUZAride is your personal chauffeur/driver partner, and we look forward
        to serving you. Check out our easy-to-use mobile application, available
        on the App Store and Google Play Store.
      </Container>
    </div>
  );
}

export default About;
