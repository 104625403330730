import React, { useEffect } from 'react';
import UAParser from 'ua-parser-js';

const QrCodeRedirect = () => {
  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    const os = result.os.name;

    if (os === "iOS") {
      window.location.href = "https://apps.apple.com/rw/app/tuzaride/id6449968083";
    } else if (os === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.hervera.tuzaride";
    } else {
      // For non-mobile OS, you can redirect to a default page or display a message
      console.log("Non-mobile OS detected.");
    }
  }, []);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default QrCodeRedirect;
