import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { booking } from './booking.reducer';
import { drivers } from './drivers.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  booking,
  drivers,
});

export default rootReducer;
