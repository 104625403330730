import React from 'react';
import './modal.css';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { BookingForm, DriversForm } from '../Forms';
import { BioCard } from '../Card';
import { setCurrentDriver } from '../../redux/actions';

function CustomModal(props) {
  const header =
    props.modalType === 'booking'
      ? 'Book this driver'
      : 'More about this driver';
  return (
    <Modal
      centered
      show={props.show}
      onHide={() => {
        props.setShow(false);
        props.setModalType('profile');
      }}
      className="booking-modal"
    >
      <Modal.Header className="booking-modal-title" closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="booking-modal-body">
        {props.modalType === 'booking' ? (
          <BookingForm driver={props.driver} setShow={props.setShow} />
        ) : (
          <BioCard
            title={props.title}
            desc={props.desc}
            image={props.image}
            setModalType={props.setModalType}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

function ModifyDrivers(props) {
  const { currentDriver } = useSelector((state) => state.drivers);
  // console.log('=================================> the id of the driver from MD:', currentDriver)
  const dispatch = useDispatch();
  return (
    <Modal
      centered
      show={props.show}
      onHide={() => {
        props.setShow(false);
        dispatch(setCurrentDriver(''));
      }}
      className="booking-modal"
    >
      <Modal.Header className="booking-modal-title" closeButton>
        <Modal.Title>Manage this driver</Modal.Title>
      </Modal.Header>
      <Modal.Body className="Modify-drivers-modal-body">
        <DriversForm
          id={currentDriver}
          action="update"
          setShow={props.setShow}
        />
      </Modal.Body>
    </Modal>
  );
}
export { CustomModal, ModifyDrivers };
