import { Route, Routes } from 'react-router-dom';
import React from 'react';

import {
  Home,
  Login,
  Signup,
  Forgot,
  Reset,
  Confirmation,
  Confirmed,
  Recover,
  Recovered,
  About,
  Admin,
  Terms,
  Contact,
  OurCustomers,
  ViewProfile,
  Privacy
} from './Pages';
import Protected from './middlewares/protected';
import Historyd from './Pages/User/history';
import DeleteAccountPage from './Pages/DeleteAccount';
import { BookingForm } from './components/Forms';
import ProtectAdmin from './middlewares/protectAdmin';
import PaymentGateway from './Pages/PaymentGateway/PaymentGateway';
import QrCode from './Pages/User/Qr-codes';
import QrCodeRedirect from './Pages/User/Qr-codes';
import NewPassword from './Pages/User/newPassword';
import VerifyTokenAtForgetPassword from './middlewares/verifyTokenAtForgetPassword';
import ShareTimes from './Pages/User/ShareTimes';

function CustomRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route
        exact
        path="/admin"
        element={
          <ProtectAdmin>
            <Admin />
          </ProtectAdmin>
        }
      />
      <Route
        exact
        path="/history"
        element={
          <Protected>
            <Historyd />
          </Protected>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <Protected>
            <ViewProfile />
          </Protected>
        }
      />
      <Route
      exact
      path='/cancel-Account'
      element = {
        <DeleteAccountPage/>
      }
      />
      <Route
        exact
        path="/profile"
        element={
          <Protected>
            <ViewProfile />
          </Protected>
        }
      />
       <Route
        exact
        path="/users/resetTokenToBeUsedOnce/:id/:token"
        element={
          <VerifyTokenAtForgetPassword>
            <NewPassword />
          </VerifyTokenAtForgetPassword>
        }
      />

      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/forgot" element={<Forgot />} />
      <Route exact path="/reset" element={<Reset />} />
      <Route exact path="/recover" element={<Recover />} />
      <Route exact path="/recovered" element={<Recovered />} />
      <Route exact path="/confirmation" element={<Confirmation />} />
      <Route exact path="/confirmed" element={<Confirmed />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/hire-a-driver" element={<BookingForm />} />
      <Route exact path="/our-customers" element={<OurCustomers />} />
      <Route exact path="/contact-us" element={<Contact />} />
      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/payment-gateway" element={<PaymentGateway />} />
      <Route exact path="/:token" element = {<ShareTimes/>} />

      <Route exact path="/qrcode" element={<QrCodeRedirect />} />
    </Routes>
  );
}

export default CustomRoutes;
