import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { FaArrowRight, FaUser } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { signup } from '../../services';
import './index.css';

function Login() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    tel: '',
    password: '',
    confirm_password: ''
  });

  const [error, setError] = useState(null)
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorPhone, setErrorPhone] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(null)
  const [showAlert, setShowAlert] = useState(false);
  const [ emailDuplicate,setDuplicateEmail] = useState(null)
  const [ TelDuplicate,setDuplicateTel] = useState(null)

  
  const navigate = useNavigate();

  const handleInput = (e) => {
    const { id, value } = e.target;
    
   const nameRegex = /^[a-zA-Z\s]+$/;
   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   const phoneRegex = /^\+2507[8293]\d{7}$/;


   

   if(id === 'name'){
    if(!nameRegex.test(value)){
      setError('invalid names')
    }else{
      setError(null)
    }
  }
  
  if(id==="email"){
    if(!emailRegex.test(value)){
      setErrorEmail("invalid email format")
    }else{
      setErrorEmail(null)
    }
  }
  if(id ==="tel"){
    if(!phoneRegex.test(value)){
      setErrorPhone("invalid phone number")
    }else{
      setErrorPhone(null)
    }
  }

  if(id ==="password"){
    if(value.length < 6){
      setErrorPassword("password should be atleast 6 characters or above ")
    }else{
      setErrorPassword(null)
    }
  }

  if(id === 'confirm_password'){
    if(value !== formData['password']){
      setErrorConfirmPassword('Password and confirm password do not match')
    }else{
      setErrorConfirmPassword(null)
    }
  }
  
  if (id === 'email') {
    setDuplicateEmail(null);
  } else if (id === 'tel') {
    setDuplicateTel(null);
  }

  
  

    setFormData({ ...formData, [id]: value });
  };

  return (
    <div className="login-base">
      <Container className="login-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <FaUser /> Signup
            </span>

            <Form>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={formData.name}
                  onChange={handleInput}
                  type="text"
                  id="name"
                  placeholder="Enter your full name"
                  required
                />
              </Form.Group>
              {error && <p style={{color: 'red'}}>{error}</p>}

              <Form.Group className="mt-3 mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  value={formData.email}
                  onChange={handleInput}
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>
              {errorEmail && <p style={{color: 'red'}}>{errorEmail}</p>}
              {emailDuplicate && <p style={{color: 'red'}}>{emailDuplicate}</p>}
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  value={formData.tel}
                  onChange={handleInput}
                  type="text"
                  id="tel"
                  placeholder="E.g:, +25078xxxxxxx, +25079xxxxxxx +25072xxxxxxx or 25073xxxxxxx"
                  required
                />
              </Form.Group>
              {errorPhone && <p style={{color: 'red'}}>{errorPhone}</p>}
              {TelDuplicate && <p style={{color: 'red'}}>{TelDuplicate}</p>}
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  onChange={handleInput}
                  value={formData.password}
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  required
                />
              </Form.Group>
              {errorPassword && <p style={{color: 'red'}}>{errorPassword}</p>}
              
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  onChange={handleInput}
                  value={formData.confirm_password}
                  type="password"
                  id="confirm_password"
                  placeholder="Confirm your password"
                  required
                />
              </Form.Group>
              {errorConfirmPassword && <p style={{color: 'red'}}>{errorConfirmPassword}</p>}

              <Button disabled={!formData.name || !formData.email || !formData.tel || !formData.password || !formData.confirm_password || !!error || !!errorEmail || !!errorPhone || !!errorPassword || !!errorConfirmPassword}
                onClick={(e) => {
                  e.preventDefault();

                  signup(formData).then((res) => {
                    if (res.status === 201) {
                      navigate('/login', { state: { isRegistered: true } });
                    }else if(res.status === 500){
                      if(res.message === "Duplicate value for tel"){
                        setDuplicateTel("telephone number exists, please use different number")
                      }

                      if(res.message === "Duplicate value for email")
                      setDuplicateEmail("email exists, please use different email") 
                    }
                     else {
                      setShowAlert(true);
                    }
                  }).catch((err) => {
                    console.log(err);
                  });
                }}
                variant="primary"
                type="submit"
                style={{ marginTop: '.6rem' }}
              >
                <FaArrowRight /> Sign up
              </Button>
              {showAlert ? (
                <Alert
                  dismissible
                  onClose={() => setShowAlert(false)}
                  style={{ marginTop: '.6rem' }}
                  variant="danger"
                >
                  Something went wrong, Try again
                </Alert>
              ) : (
                <div />
              )}
            </Form>

            <p className="mt-4">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Login;
