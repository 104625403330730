import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import { numberAppShared } from '../../services';

const SharedNumber = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    numberAppShared().then((res) => {
      if (res.status === 200) {
        // console.log('here is the console log for the shared number ============================================>:', res);
        setData(res.data);
      }
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Index', // Adding the index header
        id: 'index', // Adding an ID for the index
      },
      {
        Header: 'Name',
        accessor: 'names',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'user.tel',
      },
      {
        Header: 'Number Shared',
        accessor: 'numberShared',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div>
      <Table striped bordered hover {...getTableProps()} >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>Index</th> {/* Adding the index header */}
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td>{rowIndex + 1}</td> 
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SharedNumber;
