import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { checkTokenValidityAfterEmailLink } from '../services';

function VerifyTokenAtForgetPassword({ children }) {
  const { id, token } = useParams();
  const [isValid, setIsValid] = useState(null);
  localStorage.setItem('curretChangingPassword', JSON.stringify({ id, token }));

  useEffect(() => {
    checkTokenValidityAfterEmailLink(id, token).then((res) => {
      if (res.status === 500) {
        setIsValid(false);
      }
      if (res.status === 200) {

        setIsValid(true);
      }
    });
  }, [id, token]);

  if (isValid === null || !isValid) {
    return (
      <div className="alert alert-danger my-2" role="alert">
        The token is invalid or has expired.
      </div>
    );
  } 

  return <>{children}</>;
}

export default VerifyTokenAtForgetPassword;
