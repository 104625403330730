import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { FaArrowRight, FaKey } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './index.css';

function Reset() {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const handleInput = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div className="login-base">
      <Container className="login-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <FaKey /> Reset Password
            </span>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  onChange={handleInput}
                  value={formData.password}
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  onChange={handleInput}
                  value={formData.password}
                  type="password"
                  id="password"
                  placeholder="Confirm your password"
                />
              </Form.Group>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');
                }}
                variant="primary"
                type="submit"
                style={{ marginTop: '.6rem' }}
              >
                <FaArrowRight /> Reset Password
              </Button>
              {showAlert ? (
                <Alert
                  dismissible
                  onClose={() => setShowAlert(false)}
                  style={{ marginTop: '.6rem' }}
                  variant="danger"
                >
                  Something went wrong
                </Alert>
              ) : (
                <div />
              )}
            </Form>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Reset;
