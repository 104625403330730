// inital coordinates, Kigali city capital
export const mapCoordinates = {
  lat: -1.95544,
  lng: 30.11211,
};

export const tripOptions = [
  { name: 'Single trip', value: 'Single trip' },
  { name: 'Multiple trips', value: 'Multiple trips' },
];
