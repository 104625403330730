import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { ContactForm } from '../../components/Forms';
import './contact.css';

function Contact() {
  return (
    <div className="contact-base">
      <Container className="contact-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <div className="contact-title">Contact us</div>
            <div className="contact-desc">
              Tell us how we can help, we will reply as soon as possible
            </div>
            <div className="contact-body">
              <ContactForm />
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Contact;
