/* eslint-disable no-underscore-dangle */
import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { getSessionById, verifyDPOPayment } from '../../services';
import iPhone from '../../assets/images/destination.png';
import AppStore from '../../assets/images/appstore.png';
import AndroidApp from '../../assets/images/playstore.png';
import './home.css';
import DriverForm from '../PaymentGateway/DriverForm';
import logo from '../../assets/images/logo.png';
import momo from '../../assets/images/mtnmoney.png';
import dpo from '../../assets/images/DPOlogo.webp';
import spenn from '../../assets/images/SpennLogo.jpg';
import 'animate.css';
import background from '../../assets/images/BackgroundHome2.png';
import { Navbar, Nav } from 'react-bootstrap';
import {
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
} from 'react-icons/fa';

const notify = (message) =>
  toast.success(message, {
    duration: 15000,
    position: 'top-center',

    // Styling
    style: {},
    className: 'toasty-style',

    // Custom Icon
    icon: '👏',

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },

    // Aria
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });

function Home() {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const location = useLocation();
  const transID = new URLSearchParams(location.search).get('TransID');
  const sessionID = new URLSearchParams(location.search).get('CompanyRef');

  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(true);
  const [tripDetails, setTripDetails] = useState({})

  const verifyPayment = async() => {
    setIsLoading(true);

      const payload = {
        transactionToken: transID,
        sessionId: sessionID,
      };

    const res = await verifyDPOPayment(payload);

    if (res && res.data && res.data.paymentMessage === 'Transaction Paid') {
      
      const sessionRes = await getSessionById(sessionID);

      if (sessionRes.status === 200) {
        setTripDetails(sessionRes.data);
      }
      setPaymentSuccess(true);
    }
    setIsLoading(false)
    
  };
  

  useEffect(() => {
    if (!initialRef.current) {
      return;
    }
    initialRef.current = false;
    if (transID) {
      verifyPayment();
    }
  }, [transID]);

  return (
    <>
      {isLoading && <div id="cover-spin" />}
      {paymentSuccess && <DriverForm props={tripDetails}/>}
      <Toaster />
      {!paymentSuccess && (
        <>
          <div
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
              minHeight: '100vh'
              
            }}
          >
            <div
              className="hero-wrap d-flex justify-content-center"
              style={{
                width: '100%',
              }}
            >
              <div className="container">
                <div
                  className="imagecontainerHome ml-auto mb-4 mt-2"
                  
                >

<Navbar collapseOnSelect expand="lg" style={{ backgroundColor: 'transparent', borderRadius: '10px', width: '100%', justifyContent: 'flex-start' }}>
  <Navbar.Toggle aria-controls="responsive-navbar-nav " />
  <Navbar.Collapse id="responsive-navbar-nav" style={{marginTop:'0.5rem'}}>
    <ul className="reviews" style={{ width: '30%', display: 'flex', justifyContent: 'space-between', flexDirection:'column', listStyleType: 'none'}}>
      <li>
        <a href="https://www.tuzaride.com/terms" style={{ textDecoration: 'none', color: 'gray', fontFamily: "'Poppins', 'Urbanist', sans-serif", fontWeight: 'bold', cursor: 'pointer', padding: '10px' }}>Terms</a>
      </li>
      <li>
        <a href="http://www.tuzaride.com/about" style={{ textDecoration: 'none', color: 'gray', fontFamily: "'Poppins', 'Urbanist', sans-serif", fontWeight: 'bold', cursor: 'pointer', padding: '10px' }}>About us</a>
      </li>
    </ul>
  </Navbar.Collapse>
</Navbar>

                
                <div>
                <img
                    src={logo}
                    alt="TUZAride logo"
                    className="logoHome mb-1"
                  />
                </div>
                
                  
                </div>

                <div className='row'>
                  <div
                    className="col-lg-5 d-flex justify-content-center"
                    style={{ alignItems: 'flex-start' }}
                  >
                    <img src={iPhone} alt="iPhone" className="phone-image" />
                  </div>

                  <div className="col-lg-7 d-flex flex-column justify-content-center">
                    <div
                      className="titleHome mb-4"
                      style={{
                        fontFamily: 'Urbanist, sans-serif',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      <div className="driving-excellence">
                        <span
                          style={{
                            fontFamily: 'Rubik, sans-serif',
                          }}
                        >
                        
                          Driving Excellence.
                        </span>{' '}
                      </div>
                      <div className="connecting-lives">
                        <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                          Connecting Lives
                        </span>
                        <p
                          className="descriptionHome mb-4"
                          style={{
                            fontFamily: 'Urbanist, sans-serif',
                            textAlign: 'justify',
                            fontSize: '0.8rem',
                            paddingTop: '20px',
                            fontWeight: 'normal',
                          }}
                        >
                          TUZAride is a revolutionary driver system that aims to
                          transform the way people commute. With TUZAride, you
                          can bid farewell to the hassle of searching for a
                          reliable and trustworthy driver. Our innovative
                          platform connects passengers with skilled and licensed
                          drivers, ensuring a safe and comfortable journey every
                          time.
                        </p>
                      </div>
                    </div>

                    <div className="download mt-4">
                      <div className="download-links">
                        <a
                          href="https://apps.apple.com/rw/app/tuzaride/id6449968083"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={AppStore}
                            alt="Download Our App from Apple Store"
                            className="appstore-img"
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.hervera.tuzaride"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={AndroidApp}
                            alt="Download Our App from Google Play"
                            className="android-img"
                          />
                        </a>
                      </div>
                    </div>
                    <h3 className="pay-method-text" style={{ color: 'black' }}>
                      Accepted <strong> Payment </strong>method
                    </h3>
                    <div className="col-md-12 col-sm-12 col-xs-12 whitebg">
                      <div className="pay-tile col-md-2 col-sm-2 col-xs-2">
                        <img
                          src={dpo}
                          className="center-block img-responsive picon"
                          data-toggle="tooltip"
                          alt="Cash on Delivery"
                          title="Cash on Delivery"
                        />
                      </div>
                      <div className="col-md-2 col-sm-2 col-xs-2 pay-tile">
                        <img
                          src={spenn}
                          className="center-block img-responsive picon"
                          data-toggle="tooltip"
                          alt="spenn"
                          title="spenn"
                        />
                      </div>
                      <div className="col-md-2 col-sm-2 col-xs-2 pay-tile">
                        <img
                          src={momo}
                          className="center-block img-responsive picon"
                          data-toggle="tooltip"
                          alt="momo"
                          title="momo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            
          </div>
          <div className="footerHome">
    <div className="icon-container">
      <a href="your_facebook_link" target="_blank" rel="noopener noreferrer">
        <FaFacebook className="icon" />
      </a>
      <a href="your_whatsapp_link" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="icon" />
      </a>
      <a href="your_instagram_link" target="_blank" rel="noopener noreferrer">
        <FaInstagram className="icon" />
      </a>
    </div>
</div>
          
        </>
      )}
    </>
  );
}

export default Home;
