import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { HiMail } from 'react-icons/hi';
import './index.css';

function Recover() {
  return (
    <div className="login-base">
      <Container className="login-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <HiMail /> Check your email
            </span>
            <p className="mt-3">
              An email with recovery instructions was sent to {}. If you
              don&apos;t see the email in your inbox, remember to try your spam
              folder as well.
            </p>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Recover;
