import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { FaArrowRight, FaKey } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { changePassword } from '../../services';

function NewPassword() {
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const[showSuccess, setShowSuccess] = useState(false)
  const [errorPassword, setErrorPassword] = useState(null)
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(null)

  const navigate = useNavigate();
  const handleInput = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    if(id ==="password"){
        if(value.length < 6){
          setErrorPassword("password should be atleast 6 characters or above ")
        }else{
          setErrorPassword(null)
        }
      }
    
      if(id === 'confirm_password'){
        if(value !== formData['password']){
          setErrorConfirmPassword('Password and confirm password do not match')
        }else{
          setErrorConfirmPassword(null)
        }
      }
  };

  return (
    <div className="login-base">
      {!showSuccess ? <Container className="login-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <FaKey /> Forgot Password
            </span>

            <Form>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Enter new password</Form.Label>
                <Form.Control
                  value={formData.password}
                  onChange={handleInput}
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                />
              </Form.Group>
              {errorPassword && <p style={{color: 'red'}}>{errorPassword}</p>}
              <Form.Group className="mt-3 mb-3">
                <Form.Label>confirming password</Form.Label>
                <Form.Control
                  value={formData.confirm_password}
                  onChange={handleInput}
                  type="password"
                  id="confirm_password"
                  placeholder="confirm password"
                />
              </Form.Group>
              {errorConfirmPassword && <p style={{color: 'red'}}>{errorConfirmPassword}</p>}
              <Button disabled={!!errorPassword || !!errorConfirmPassword}
                onClick={(e) => {
                  e.preventDefault();
                  const currentChangingPasswordUser = JSON.parse(localStorage.getItem('curretChangingPassword'));
                  changePassword(currentChangingPasswordUser.id, currentChangingPasswordUser.token, formData.password) 
                  .then((res)=>{
                    if(res.status === 200){
                        setShowSuccess(true)
                    }
                  })                 
                }}
                variant="primary"
                type="submit"
                style={{ marginTop: '.6rem' }}
              >
                <FaArrowRight /> Recover password
              </Button>
              {showAlert ? (
                <Alert
                  dismissible
                  onClose={() => setShowAlert(false)}
                  style={{ marginTop: '.6rem' }}
                  variant="danger"
                >
                  Something went wrong
                </Alert>
              ) : (
                <div />
              )}
            </Form>
          </div>
        </Card>
      </Container>:(
         <div className="alert alert-success mt-3" role="alert" style={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold' }}>
         Your password has been changed successfully! Now you can log in with your new password.
       </div>
       
        )}
    </div>
  );
}

  
  

export default NewPassword;
