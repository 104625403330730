import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './index.css';

function Confirmed() {
  return (
    <div className="login-base">
      <Container className="login-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <FaSignInAlt /> Confirmed
            </span>
            <p className="mt-3">
              You have successfully confirmed you email. It is time to explore
              your account
            </p>
            <Link to="/login" style={{ whiteSpace: 'nowrap' }}>
              login to access your account
            </Link>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Confirmed;
