import React, { useEffect, useState } from 'react';
import { Container, Image, Card, Button, Modal, Form } from 'react-bootstrap';
import avatar from '../../assets/images/avatar.png';
import { getUserProfile, updateUserProfile } from '../../services';


const authToken = localStorage.getItem('token');

const EditProfileModal = ({ user, show, onHide, updateUser }) => {
  

    const [payload, setPayload] = useState({
      name: "",
      email: "",
      tel: ""
    })

    useEffect(() => {
      setPayload(user);
    }, [user]);

    const [error, setError] = useState(null)
    const [errorEmail, setErrorEmail] = useState(null)
    const [errorPhone, setErrorPhone] = useState(null)
    const [ emailDuplicate,setDuplicateEmail] = useState(null)
    const [ TelDuplicate,setDuplicateTel] = useState(null)

    const handleOnChange = (event)=>{
      const {id, value} = event.target;
      setPayload({...payload, [id]: value})

      const nameRegex = /^[a-zA-Z\s]+$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^\+2507[8293]\d{7}$/;

      if(id === 'name'){
        if(!nameRegex.test(value)){
          setError('invalid names')
        }else{
          setError(null)
        }
      }
      
      if(id==="email"){
        if(!emailRegex.test(value)){
          setErrorEmail("invalid email format")
        }else{
          setErrorEmail(null)
        }
      }
      if(id ==="tel"){
        if(!phoneRegex.test(value)){
          setErrorPhone("invalid phone number")
        }else{
          setErrorPhone(null)
        }
      }

      if (id === 'email') {
        setDuplicateEmail(null);
      } else if (id === 'tel') {
        setDuplicateTel(null);
      }

    }


    const handleUpdateProfile = async () => {
        updateUserProfile(payload, authToken).then((res)=>{
          




          if(res.status === 200){
          const updatedUser = { ...user, ...payload };
          updateUser(updatedUser);
          onHide();
          
          }else if(res.status === 500){
            if(res.message === "Duplicate value for tel"){
              setDuplicateTel("telephone number exists, please use different number")
            }
  
            if(res.message === "Duplicate value for email"){
            setDuplicateEmail("email exists, please use different email") 
          }
        }






        })
        

          
          
        }
        

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" id="name" value={payload.name} onChange={handleOnChange} />
          </Form.Group>
          {error && <p style={{color: "red"}}>{error}</p>}
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" id="email" value={payload.email} onChange={handleOnChange} />
          </Form.Group>
          {errorEmail && <p style={{color: "red"}}>{errorEmail}</p>}
          {emailDuplicate && <p style={{color: 'red'}}>{emailDuplicate}</p>}
          <Form.Group controlId="formTel">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="tel" id="tel" value={payload.tel}  onChange={handleOnChange} />
          </Form.Group>
          {errorPhone && <p style={{color: "red"}}>{errorPhone}</p>}
          {TelDuplicate && <p style={{color: 'red'}}>{TelDuplicate}</p>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button  variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button disabled={!payload.name || !payload.email || !payload.tel|| !!error || !!errorEmail || !!errorPhone || !!TelDuplicate || !! emailDuplicate} variant="primary" onClick={handleUpdateProfile}>
          Update Profile
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ViewProfile = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const defaultValues = {
    name: '',
    emal: '',
    tel: ''
  };
  const [user, setUser] = useState(defaultValues);
  const [fields, setFields] = useState(defaultValues);

  useEffect(() => {
    async function fetchUserProfile() {
        const data = await getUserProfile(authToken);
        if(data) {
            const { data: profile } = data
            const { name, email, tel } = profile;
           setUser({ name, email, tel });
           setFields({ name, email, tel });
           localStorage.setItem('currentUser', JSON.stringify(data.data));
        }
    }
    fetchUserProfile();
  }, []);

  const handleEditProfileClick = () => {
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
  };

  return (
    <Container className="my-5">
      <Card>
        <Card.Header>
          <h3 textAlign="center">Profile</h3>
        </Card.Header>
        <Card.Body>
          <Card.Title></Card.Title>
          <Image src={user.image || avatar} roundedCircle width={100} height={100} />
          <Card.Text>Name: {user.name}</Card.Text>
          <Card.Text>Email: {user.email}</Card.Text>
          <Card.Text>Phone: {user.tel}</Card.Text>
          <Button variant="primary" onClick={handleEditProfileClick}>
            Edit Profile
          </Button>
        </Card.Body>
      </Card>

      <EditProfileModal
        user={user}
        fields={fields}
        setFields={setFields}
        show={showEditModal}
        onHide={handleEditModalClose}
        updateUser={updateUser} // Pass the updateUser function as a prop
      />
    </Container>
  );
};

export default ViewProfile;
