import { bookingConstants } from '../constants';

// const currentBooking = JSON.parse(localStorage.getItem('booking'));

const initialState = {
  bookings: [],
  userBookings: [],
  currentBooking: {},
};

export function booking(state = initialState, action = {}) {
  switch (action.type) {
    case bookingConstants.SET_BOOKINGS:
      return {
        ...state,
        bookings: [...action.bookings].reverse(),
      };
    case bookingConstants.SET_USER_BOOKINGS:
      return {
        ...state,
        userBookings: [...state.userBookings, action.currentBooking],
      };
    case bookingConstants.SET_CURRENT_BOOKING:
      return {
        ...state,
        currentBooking: action.currentBooking,
      };
    case bookingConstants.RESET_BOOKING:
      return {
        ...state,
        currentBooking: {},
      };
    case bookingConstants.DELETE_SESSION:
      return {
        ...state,
        bookings: state.bookings.filter((booking) => booking._id !== action.payload),
      }
    default:
      return state;
  }
}
