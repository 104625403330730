import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import { sharedNumber } from '../../services';

const ShareTimes = () => {
    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        const os = result.os.name;
    
        if (os === "iOS") {
          window.location.href = "https://apps.apple.com/rw/app/tuzaride/id6449968083";
        } else if (os === "Android") {
          window.location.href = "https://play.google.com/store/apps/details?id=com.hervera.tuzaride";
        } else {
        //   console.log("Non-mobile OS detected.");
        }
      }, []);
      
      const {token } = useParams();
      
      sharedNumber(token).then((res)=>{
        if(res == 200){
            // console.log('this worked======================>')
        }
      })
      
  return (
    <div>
        redirecting ...
    </div>
  )
}

export default ShareTimes
