import { driverConstants } from '../constants';

const initialState = {
  drivers: [],
  currentDriver: {},
};

export function drivers(state = initialState, action = {}) {
  switch (action.type) {
    case driverConstants.SET_DRIVERS:
      return {
        ...state,
        drivers: [...action.drivers].reverse(),
      };
    case driverConstants.SET_CURRENT_DRIVER:
      return {
        ...state,
        currentDriver: action.driver,
      };
    default:
      return state;
  }
}
