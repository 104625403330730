import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { saveMessage } from '../../services';

const Message = () => {
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMessage(message).then((res) => {
      if (res.status === 200) {
        setAlert({ type: 'success', text: 'Successfully sent!' });
        setMessage(''); // Clear the form data
      } else {
        setAlert({ type: 'danger', text: 'Not sent!' });
      }
    });
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          {alert && (
            <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
              {alert.text}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="messageForm">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Type your message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Message;
