import { bookingConstants } from '../constants';

export const setBookings = (bookings) => {
  return {
    type: bookingConstants.SET_BOOKINGS,
    bookings,
  };
};

export const deleteBooking= (id) => {
  return {
    type : bookingConstants.DELETE_SESSION,
    payload: id
  }
}