import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getUserProfile } from '../services';

const token = localStorage.getItem('token');
const isLoggedIn = token !== null;

function ProtectAdmin({children}){
  const [role, setRole] = useState(null); 

  const fetchUserProfile = async () => {
   
    if (isLoggedIn) {
      try {
        const data = await getUserProfile(token);
        setRole(data.data.role);
        console.log(data.data.role);
      } catch (error) {
        console.error("Failed to get user profile: ", error);
      }
    }
  };

  fetchUserProfile();

  if (role === null) {
    return null;
  }

  return role === 'admin' ? children : <Navigate to="/hire-a-driver" replace />;
}

export default ProtectAdmin;
