import React from 'react';
import { Container, Card, Alert } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LoginForm } from '../../components/Forms';
import { useLocation } from 'react-router-dom';
import './index.css';

function Login() {
  const location = useLocation();

  return (
    <div className="login-base">
      <Container className="login-body">
        {location && location.state && location.state.isRegistered &&
          <Alert dismissible variant="success">
            Successful registered, Continue to login
          </Alert>
        }
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <FaSignInAlt /> Login
            </span>
            <LoginForm />
            <p className="mt-4">
              First time user? <Link to="/signup">Sign Up</Link>
            </p>
            <Link to="/forgot">Forgot password?</Link>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Login;
