import axios from 'axios';
import Fetch from '../helpers/fetch';
import { BASE_URL, CLOUD_NAME } from './config';

const url = `${BASE_URL}/drivers`;
const numberOfDriversURL = `${BASE_URL}/drivers/numberOfDrivers`
const cloudinaryURL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`;
const token = localStorage.getItem('token');

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${token}`
};

export const getDrivers = async () => {
  const drivers = await Fetch(url, 'GET', undefined);
  return drivers.json();
};
const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', 'tysx3den');
  const uploadedFile = await fetch(cloudinaryURL, {
    method: 'POST',
    body: formData,
  });
  console.log(uploadedFile);
  return uploadedFile.json();
};

export const addDrivers = async (body) => {
  const result = await uploadFile(body.image);
  body.image = result.secure_url;
  const newDrivers = await Fetch(`${url}/add`, 'POST', body);
  return newDrivers.json();
};

export const updateDrivers = async (body, id) => {
  const { email, ...restBody } = body;
  const updatedBody = email ? { email, ...restBody } : restBody;

  // console.log("the body==========================================================>",updatedBody);

  let { image } = body;
  if (image) {
    const result = await uploadFile(image);
    image = result.secure_url;
  }
  const updatedDrivers = await Fetch(`${url}/update/${id}`, 'PATCH', updatedBody);
  return updatedDrivers.json();
};

export const deleteDrivers = async (id) => {
  const deletedDrivers = await Fetch(
    `${url}/delete/${id}`,
    'DELETE',
    undefined
  );
  return deletedDrivers.json();
};

export const numberOfDrivers = async()=>{
  const response = await axios.get(numberOfDriversURL, {headers : headers})
  return response.data
}


const apis = { getDrivers, addDrivers, deleteDrivers };

export default apis;
