import React from 'react';
import Spinner from './Spinner';
import './Card.css';

function Card({ loading, message }) {
  return (
    <>
    {loading && (
      <div className="backdrop">
        <div className="card">
          <Spinner />
          <p>{message}</p>
        </div>
      </div>
    )}
    </>
  );
}

export default Card;
