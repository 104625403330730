import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { FaArrowRight, FaKey } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { recoverPassword } from '../../services';

function Forgot() {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')


  const navigate = useNavigate();
  const handleInput = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await recoverPassword(formData);
      if (result.status === 201) {
        navigate('/recover');
      } else if(result.message === "no user with this email") {
        setAlertMessage("No user with this email!");
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-base">
      <Container className="login-body">
        <Card className="shadow">
          <div className="mb-3 p-4">
            <span className="sub-header">
              {' '}
              <FaKey /> Forgot Password
            </span>

            <Form>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  value={formData.email}
                  onChange={handleInput}
                  type="text"
                  id="email"
                  placeholder="Enter your email"
                />
              </Form.Group>

              <Button
                onClick={handleRecoverPassword}
                variant="primary"
                type="submit"
                style={{ marginTop: '.6rem' }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <FaArrowRight />
                )}{' '}
                Recover password
              </Button>
              {showAlert ? (
                <Alert
                  dismissible
                  onClose={() => setShowAlert(false)}
                  style={{ marginTop: '.6rem' }}
                  variant="danger"
                >
                  {alertMessage}
                </Alert>
              ) : (
                <div />
              )}
            </Form>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default Forgot;
