import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './router';
import { Header, Footer } from './components/Navs';
import './App.css';

export const scrollPages = ['/', '/our-customers'];

function App() {
  const [navColor, setnavColor] = useState('transparent');

  const isScrollPage = scrollPages.includes(window.location.pathname);

  const [isAdminPath, setIsAdminPath] = useState(window.location.pathname !== '/'); //added

  const handleRouteChange = () => {
    setIsAdminPath(window.location.pathname !== '/'); // added
  };

  const listenScrollEvent = () => {
    return window.scrollY > 10
      ? setnavColor('white')
      : setnavColor('transparent');
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    window.addEventListener('popstate', handleRouteChange); //added
    

    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
      window.removeEventListener('popstate', handleRouteChange); //added

    };
  }, []);



  return (
    <Router>
      <div className="App">
       { isAdminPath  && (<Header 
          navColor={isScrollPage ? navColor : 'white'}
          isFixed={isScrollPage}
        />)}
        <Routes />
        { isAdminPath  && (<Footer style={{ marginTop: '1.4rem' }} />)}
        
      </div>
    </Router>
  );
}

export default App;
