import axios from 'axios';
import Fetch from '../helpers/fetch';
import { BASE_URL } from './config';


const signupURL = `${BASE_URL}/users/signup`;
const loginURL = `${BASE_URL}/users/login`;
const getUserProfileURL = `${BASE_URL}/users/profile`;
const updateUserProfileURL = `${BASE_URL}/users/updateUser`;
const userBookingHistoryURL = `${BASE_URL}/sessions/history`;
const deleteAccountURL = `${BASE_URL}/users/deleteUsersAccount`;
const recoverAccountURL = `${BASE_URL}/users/forgetPassword`
const numberOfUsersURL = `${BASE_URL}/users/usersNumber`;
const reviewsURL = `${BASE_URL}/users/allReviews`
const feedBackForAdriverURL = `${BASE_URL}/users/findFeedbackforadriver`
const sharedNumberURL = `${BASE_URL}/users/pullAllSharedTimes`
const saveMessageURL = `${BASE_URL}/users/saveMessage`
const shareTimesURL = `${BASE_URL}/users/saveShareTimes`


const recoverAccountUrlAfterEmail = `${BASE_URL}/users/resetTokenToBeUsedOnce`

const saveFeedUrl = `${BASE_URL}/users/feedback`;


const token = localStorage.getItem('token')

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${token}`
};

export const signup = async (raw) => {
  const response = await Fetch(signupURL, 'POST', raw);
  return response.json();
};

export const login = async (raw) => {
  const response = await Fetch(loginURL, 'POST', raw);
  return response.json();
};



export const recoverPassword = async (email)=>{
  const response =  await Fetch(recoverAccountURL, 'POST', email);
  return response.json()
}

export const getUserProfile = async (authToken) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  };
  const response = await axios.get(getUserProfileURL, { headers: headers });
  return response.data;
};

export const checkTokenValidityAfterEmailLink = async(id, token)=>{
  const response = await Fetch(`${recoverAccountUrlAfterEmail}/${id}/${token}`, 'GET')
  return response.json();
} 

export const changePassword = async(id, token, password)=>{
  const response = await Fetch(`${recoverAccountUrlAfterEmail}/${id}/${token}`, 'POST', {password:password});
  return response.json()
}

export const updateUserProfile = async (payload, authToken) => {
  const response = await fetch(updateUserProfileURL, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify(payload)
  });

  return response.json();
}




export const getUserBookingHistory = async (authToken) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  };
  const response = await axios.get(userBookingHistoryURL, { headers: headers });
  return response.data;
};


export const deleteAccount = async (reqBody, authToken) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  };
  const response = await axios.delete(deleteAccountURL, { headers: headers, data: reqBody });
  return response.data;
};

export const saveFeedback = async (reqbody, authToken)=>{
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  };
  const response = await axios.post(saveFeedUrl,reqbody, {headers: headers});
  return response.data
}

export const numberOfUsers = async()=>{
  const response = await axios.get(numberOfUsersURL, {headers: headers})
  return response.data
}

// export const reviews = async ()=>{
//   const response = await axios.get(reviewsURL)
//   return response.data
// }

export const findFeedbackforadriver = async(id)=>{
  const response = await axios.get(`${feedBackForAdriverURL}/${id}`, {headers: headers})
  return response.data
}

export const numberAppShared = async()=>{
  const response = await axios.get(sharedNumberURL, {headers: headers})
  return response.data
}

export const saveMessage = async(message)=>{
  const response = await axios.post(saveMessageURL,{message: message}, {headers:headers})
  return response.data
}

export const sharedNumber = async(token)=>{
  const response = await axios.get(`${shareTimesURL}/${token}`)
  return response.data
}

export const removeUserFromLocalStorage = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
  localStorage.removeItem('currentUser');
}


