import React, { useEffect, useState } from 'react';
import { getUserBookingHistory } from '../../services';
import './history.css';
import { Dropdown, Button, Card, Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import { FaClock, FaLocationArrow, FaArrowLeft, FaArrowRight, FaBackward, FaForward } from 'react-icons/fa';


const AdditionalInfo = ({ item, onBack }) => {


  
  const data = React.useMemo(
    () => [
      { label: <strong>Client Names</strong>, value: item.clientNames },
      { label: <strong>Pickup Location</strong>, value: item.pickupLocation },
      { label: <strong>Destination</strong>, value: item.destination },
      { label: <strong>Status</strong>, value: item.status === 'Pending' ? 'Not Complete' : item.status },
      { label: <strong>Distance</strong>, value: item.distance },
      { label: <strong>Start Date</strong>, value: item.startDate },
      { label: <strong>End Date</strong>, value: item.endDate },
      { label: <strong>Duration</strong>, value: item.duration },
      {
        label: <strong>Price</strong>,
        value: (
          <span>
            {`${item.price} Rwf `}
            {item.hasPaid ? (
              <span style={{ color: 'green' }}>(Paid)</span>
            ) : (
              <span style={{ color: 'red' }}>(Not Paid)</span>
            )}
          </span>
        ),
      },
      { label: <strong>Vehicle Type</strong>, value: item.vehicleType },
      { label: <strong>Gearbox</strong>, value: item.gearbox },
      { label: <strong>Vehicle engine</strong>, value: item.vehicleType },


      { 
        label: <div className="driver-title">Driver</div>,
        value: (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={item.driver.image} alt="Driver" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%', marginRight: '15px' }} />
            <div>
              <div className="driver-name">{item.driver.name}</div>
              <div className="driver-category">{item.driver.bio}</div>
            </div>
          </div>
        ),
      },
      
      
    ],
    [item]
  );

  const columns = React.useMemo(
    () => [
      { Header: '', accessor: 'label' },
      { Header: '', accessor: 'value' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
  <Card className="shadow-lg p-3 mb-5 bg-body rounded" style={{marginTop: "20px"}}>
  <Card.Body>
    <Card.Title style={{color: '#FF5F00', fontWeight:'bold'}}>Booking details</Card.Title>
    <Table {...getTableProps()} striped hover className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
              {i === data.length - 2 && (
                <tr style={{ borderTop: "2px solid #f3f3f3" }}></tr>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
    <Button variant="secondary" onClick={onBack}>
      Back
    </Button>
  </Card.Body>
</Card>

  
  );
};

const History = () => {
  const [historyData, setHistoryData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5); // Initial page size is set to 5

  useEffect(() => {
    const authToken = localStorage.getItem('token');

    const fetchUserHistory = async () => {
      const response = await getUserBookingHistory(authToken);
      const { data } = response;
      setHistoryData(data);
    };
    fetchUserHistory();
  }, []);

  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  const handleBack = () => {
    setSelectedItem(null);
  };

  // New logic for pagination
  const paginatedData = historyData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  const canNextPage = pageIndex < Math.ceil(historyData.length / pageSize) - 1;
  const canPreviousPage = pageIndex > 0;
  const gotoPage = (page) => setPageIndex(page);
  const nextPage = () => canNextPage && setPageIndex(prev => prev + 1);
  const previousPage = () => canPreviousPage && setPageIndex(prev => prev - 1);

  if (selectedItem) {
    return <AdditionalInfo item={selectedItem} onBack={handleBack} />;
  }

  return (
    <>
      <div className="main-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {paginatedData.map((item) => (
          <Card className="history-card" key={item._id}>
            <Card.Body className="history-card-body">
              <Card.Title className="history-card-title">{item.pickupLocation} to {item.destination}</Card.Title>
              <Card.Subtitle className="history-card-subtitle">
                <div className="flex-container">
                  <span className="trip-type">{item.tripType}</span>
                  <span className="price">{item.price} Rwf</span>
                </div>
              </Card.Subtitle>
              <Card.Text className="history-card-text">
                <div className="flex-container">
                  <p className={item.hasPaid ? "history-card-paid" : "history-card-notpaid"}>{item.hasPaid ? 'Paid' : 'Not Paid'}</p>
                  <p className="distance"><FaLocationArrow/> {item.distance}</p>
                  <p className="duration"><FaClock style={{color: '#FF5F00'}}/> {item.duration}</p>
                </div>
              </Card.Text>
              <Dropdown className="history-card-action">
                <Dropdown.Toggle variant="secondary" id="dropdown-toggle"></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSelectItem(item)}>More</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-container">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {<FaBackward/>}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {<FaArrowLeft/>}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {<FaArrowRight/>}
        </button>
        <button onClick={() => gotoPage(Math.ceil(historyData.length / pageSize) - 1)} disabled={!canNextPage}>
          {<FaForward/>}
        </button>
        <span>
          Page <strong>{pageIndex + 1} of {Math.ceil(historyData.length / pageSize)}</strong>
        </span>
        <span>
          | Go to page:
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPageIndex(0); // Reset to the first page
          }}
        >
          {[5, 10, 15, 20, 25, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default History;