import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DeleteAccount.css';
import { deleteAccount, removeUserFromLocalStorage, saveFeedback } from '../../services';

const SuccessPopup = ({ message, onClose }) => {
    const navigate = useNavigate();
  
    const handleClose = () => {
      
      onClose();
      removeUserFromLocalStorage()
      navigate("/");
    };
  
    return (
      <div className="success-popup">
        <p>{message}</p>
        <button onClick={handleClose}>Close</button>
      </div>
    )
  };

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [warningAccepted, setWarningAccepted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [password, setPassword] = useState('');
  const [isFeedback, setIsFeedback] = useState({
    feedback: ''
  })
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const authToken = localStorage.getItem('token');

  const handleWarningAcceptance = () => {
    setWarningAccepted(!warningAccepted);
  };

  const handleNextButtonClick = () => {
    if (warningAccepted) {
      setShowConfirmation(true);
    }
  };

  const handleFeedbackChange = (event)=>{
    const {id, value} = event.target
    setIsFeedback({...isFeedback, [id]: value})
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginButtonClick = () => {
      navigate("/login", { state: { fromDeleteAccount: true } });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const reqBody = {
        password: password
      };
      await deleteAccount(reqBody, authToken);
      await saveFeedback(isFeedback, authToken);
      
      setShowSuccessPopup(true);
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  if (showSuccessPopup) {
    return (
      <SuccessPopup message="Account deleted successfully" onClose={handleCloseSuccessPopup} />
    );
  }

  if (showConfirmation) {
    if (authToken) {
      return (
        <div className='container'>
        <div className="delete-account-container">
          <h1 className="delete-account-title">Delete Account</h1>
          <div className="delete-account-content">
            <p>
              Are you sure you want to delete your account? This action is
              irreversible and will result in the complete removal of your profile,
              personal details, preferences, and any associated data.
            </p>
          </div>
          <form className="delete-account-form" onSubmit={handleSubmit}>
            <label className="delete-account-form-label">
              Password:
              <input
                className="delete-account-form-input"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </label>
            <br />
            <button className="delete-account-form-button" type="submit">
              Confirm Delete Account
            </button>
          </form>
        </div>
        </div>
      );
    } else {
      return (
        <div className="delete-account-container">
          <h1 className="delete-account-title">Not Logged In</h1>
          <div className="delete-account-content">
            <p>
              It seems you are not logged in. Please log in to delete your account.
            </p>
            <button className="login-button" onClick={handleLoginButtonClick}>Log in</button>
          </div>
        </div>
      )
    }
  }

  return (
    <div className='container'>
      <div className="delete-account-container">
        <h1 className="delete-account-title">Account deletion</h1>
        <div className="delete-account-content">
          <p className='text-align-justify'>
            Please keep in mind that deleting your account involves the complete
            removal of your profile, personal details, preferences, and any
            associated data. This includes your booking history, payment
            information, and any other records that may have been stored within
            the app. Furthermore, it also means that any accumulated rewards or
            loyalty points will be lost. We understand that circumstances may
            arise where you may wish to discontinue using our app, but we
            encourage you to carefully weigh the implications before making a
            final decision. If you have any concerns about data privacy or
            security, we are more than happy to address them and explore
            alternative solutions that might better suit your needs. Once again,
            we want to emphasize that deleting your account is an irreversible
            process. If you are absolutely certain about proceeding, please be
            aware that you will permanently lose access to all your data and
            content.
          </p>
          <label className="delete-account-checkbox-label">
            <input
              className="delete-account-checkbox"
              type="checkbox"
              checked={warningAccepted}
              onChange={handleWarningAcceptance}
            />
            I understand and accept the consequences
          </label>
          <br />
          {warningAccepted && (
            <textarea
              className="delete-account-textarea"
              required
              placeholder="Reason for deletion"
              id='feedback'
              value={isFeedback.feedback}
              onChange={handleFeedbackChange}
            />
          )}
        </div>
        <button
          className="delete-account-button"
          onClick={handleNextButtonClick}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DeleteAccount;
