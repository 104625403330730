import React, { useState } from "react";
import DPOlogo from '../../assets/images/DPOlogo.webp'
import mtnMOMO from '../../assets/images/mtnMOMO.jpg'
import SpennLogo from '../../assets/images/SpennLogo.jpg'
import './PaymentGateway.css'
import { checkMoMoPayment, checkSpennPayment, requestMoMoPayment, requestSpennPayment,requestDpoPayment } from '../../services';
import { v4 as uuidv4} from 'uuid'
import Card from './Card';
import DriverForm from "./DriverForm";

const currentBooking = JSON.parse(localStorage.getItem('currentBooking'));

const PaymentOption = ({ name, logo, onSelect, isSelected, onClick }) => (
  <div 
    className={`payment-option ${isSelected ? 'selected' : ''}`}
    onClick={() => {
      onSelect(name);
      onClick && onClick();
    }}
  >
    <img src={logo} alt={name} width={80} style={{marginBottom: '10px'}} />
    <h6><strong>{`Pay with ${name}`}</strong></h6>
  </div>
);


const PhoneNumberInput = ({ phoneNumber, setPhoneNumber, selectedGateway, setSelectedGateway }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);



  const HandleInputsNumber = (event) => {
    setPhoneNumber(event.target.value);  
  const phoneNumberRegexMomo = /^\+25078\d{7}$/;
  const phoneNumberRegexSpenn = /^\+250\d{9}$/;
  
  if (selectedGateway === 'SPENN' && !phoneNumberRegexSpenn.test(event.target.value)) {
      setError('Invalid phone number. It should be in the format +250xxxxxxxxx');
  } else if (selectedGateway === 'MOMO' && !phoneNumberRegexMomo.test(event.target.value)) {
      setError('Invalid phone number. It should be in the format +25078xxxxxxx');
  } else {
      setError(null);
  }
  }

  const handlePayment = () => {
    
    if (selectedGateway === 'SPENN') { 
      setLoading(true);
      setMessage("Sending request to pay on SPENN app");
      const currentBookings = JSON.parse(localStorage.getItem('currentBooking'));
      const payload = {
        amount: currentBookings.price,
        message: "Pay the booked trip on Tuzaride",
        externalReference: currentBookings._id,
        phoneNumber: phoneNumber
      }
      requestSpennPayment(payload)
  .then((data) => {
    setMessage("Check your SPENN app, payment is pending");
    const checkPaymentInterval = setInterval(() => {
      checkSpennPayment(currentBooking._id)
        .then((res) => {
          if (res.data.requestStatus === "Approved") {
            setLoading(false);
            clearInterval(checkPaymentInterval);
            setIsPaymentSuccessful(true);
          }
        });
    }, 5000);

    setTimeout(() => {
      clearInterval(checkPaymentInterval);
      setLoading(false);
      setMessage("Payment failed. Please try again.");
    }, 900000);
  });

        
    } else if(selectedGateway === 'MOMO'){
      
    setLoading(true);
    setMessage("Sending request to pay on MOMO app");
    const currentBookings = JSON.parse(localStorage.getItem('currentBooking'));
      const payload = {
        sessionId: currentBookings._id,
        amount:currentBookings.price,
        phoneNumber:phoneNumber,
        externalReference: uuidv4()
      }
      requestMoMoPayment(payload)
  .then((data) => {
    const checkPaymentInterval = setInterval(() => {
      checkMoMoPayment(payload.externalReference)
        .then((res) => {
          if(res.data.reason === "INTERNAL_PROCESSING_ERROR"){
            setLoading(false)
            setError(`${res.data.reason} : insufficient MOMO balance`)
            clearInterval(checkPaymentInterval)
          }
          setMessage(
            "Check your phone, payment is pending. If you didn't get the message in a pop-up, type *182*7*1# to finish payment"
          );
          if (res.data.status === "SUCCESSFUL") {
            setLoading(false);
            clearInterval(checkPaymentInterval);
            setIsPaymentSuccessful(true);
          }
        });
    }, 5000);

    setTimeout(() => {
      clearInterval(checkPaymentInterval);
      setLoading(false)
      setError("Payment failed. Please try again.");
    }, 900000);
  });

    }
  }

  
  return (
    isPaymentSuccessful ? 
    <DriverForm/> : 
    (
      <div className="d-flex flex-column">
        <h2 className="mt-5 mb-3 text-center"><strong>Pay with {selectedGateway}</strong></h2>
        <div className="phone-input mb-5">
          <h6><strong>Enter your {selectedGateway} phone number</strong></h6>
            <input 
              type="text" 
              value={phoneNumber} 
              onChange={HandleInputsNumber} 
              placeholder={selectedGateway === 'MOMO' ? "+25078xxxxxxx" : "+250xxxxxxxxx"}
            />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div className="button-group">
            <button onClick={() => setSelectedGateway(null)}>Back</button>
            <button onClick={handlePayment} disabled={error || phoneNumber === ""}>Pay</button>
          </div>
          <Card loading={loading} message={message} />
        </div>
      </div>
    )
  );
  
  };

function PaymentGateway() {
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");


  return (
    <div className="payment-options">
      {selectedGateway && (selectedGateway === 'MOMO' || selectedGateway === 'SPENN') ? (
        <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} selectedGateway={selectedGateway} setSelectedGateway={setSelectedGateway} />
      ) : (
        <>
        <div style={{display:'flex', flexDirection: 'column', alignItems:'center'}}>
          <h2 className="mt-5 mb-5"><strong>Choose your payment mode</strong></h2>
          <div style={{display: 'flex'}}>
          <PaymentOption name="MOMO" logo={mtnMOMO} onSelect={setSelectedGateway} isSelected={selectedGateway === 'MOMO'} />
          <div className="px-3"/>
          <PaymentOption name="SPENN" logo={SpennLogo} onSelect={setSelectedGateway} isSelected={selectedGateway === 'SPENN'} />
          <div className="px-3"/>
          <PaymentOption 
  name="DPO" 
  logo={DPOlogo} 
  onSelect={setSelectedGateway} 
  isSelected={selectedGateway === 'DPO'} 
  onClick={selectedGateway === 'DPO' ? () => {
    
    const currentBooking = JSON.parse(localStorage.getItem('currentBooking'));
    requestDpoPayment({clientNames: currentBooking.clientNames, pickupLocation: currentBooking.pickupLocation, 
      destination: currentBooking.destination, price:currentBooking.price})
    .then((res)=>{
      if(res.status === 201){
            localStorage.setItem('dpoPayment', JSON.stringify(res.data));
            window.location.replace(`https://secure.3gdirectpay.com/payv3.php?ID=${res.data.transToken}`);
      }
    })

    
  } : null}
/>
</div>
</div>

        </>
      )}
    </div>
  );
}


export default PaymentGateway;
