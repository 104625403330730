import React from 'react';
import Container from 'react-bootstrap/Container';
import './index.css';

function Privacy() {
  return (
    <div className="privacy-base">
      <Container className="privacy-body">
        <span className="privacy-title"><strong> Privacy Policy for TUZAride</strong></span>
        <br/>
        <h6>Effective Date: May,1 2023</h6>
        <br/>
        <p>
        At TUZAride, we are committed to protecting the privacy and confidentiality of our users'
        personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the
        personal information of individuals who use our on-demand personal driver hailing platform in
        Rwanda-Kigali. By using TUZAride, you agree to the terms and conditions outlined in this policy.
        </p>
        <h6 style={{ paddingBottom: '15px' }}><strong>1. Information We Collect</strong></h6>
        <h6><strong>1.1 Personal Information</strong></h6>
        <p>We may collect personal information when you create an account or use our platform, including
        but not limited to</p>
        <ul>
          <li>● Full name</li>
          <li>● Contact information (phone number, email address)</li>
          <li>● Profile picture</li>
          <li>● Payment information (credit card details)</li>
          <li>● GPS location data</li>
        </ul>

        <h6><strong>1.2 Usage Information</strong></h6>
        <p>We collect information about your interactions with our platform, such as ride history, driver
        ratings, and preferences.</p>

        <h6 style={{ paddingBottom: '15px' }}><strong>2. Use of Information</strong></h6>
        <h6><strong>2.1 Providing Services</strong></h6>
        <p>We use your personal information to provide and improve our services, including facilitating
        rides, processing payments, and ensuring a safe and efficient user experience.</p>

        <h6><strong>2.2 Communication</strong></h6>
        <p>We may use your personal information to communicate with you regarding service updates,
        promotional offers, and important notifications.</p>

        <h6><strong>2.3 Customer Support</strong></h6>
        <p>Your information helps us respond to your inquiries, troubleshoot issues, and provide customer
        support.</p>

        <h6><strong>2.4 Safety and Security</strong></h6>
        <p>We use personal information to maintain a secure and safe environment for all users, including
        verifying driver identities, monitoring for fraud, and investigating and resolving disputes or
        incidents.</p>

        <h6><strong>2.5 Analytics and Improvements</strong></h6>
        <p>We may analyze user data to improve our services, enhance user experiences, and develop new
        features.</p>

        <h6 style={{ paddingBottom: '15px' }}><strong>3. Sharing of Information</strong></h6>
        <h6><strong>3.1 With Drivers</strong></h6>
        <p>We share your personal information with drivers to facilitate rides and ensure a smooth pickup
        and drop-off experience.</p>

        <h6><strong>3.2 Service Providers</strong></h6>
        <p>We may engage trusted third-party service providers who assist us in operating our platform,
        processing payments, analyzing data, and delivering services. These providers have access to
        personal information as necessary to perform their functions but are prohibited from using it for
        other purposes.</p>

        <h6><strong>3.3 Legal Requirements</strong></h6>
        <p>We may disclose personal information to comply with applicable laws, regulations, or legal
        processes or to respond to valid requests from public and government authorities.</p>

        <h6><strong>3.4 Business Transfers</strong></h6>
        <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, personal
        information may be transferred as part of the transaction. We will notify you of any such change
        in ownership or control of your personal information.</p>
        
        <h6><strong>4. Data Retention</strong></h6>
        <p>We retain personal information for as long as necessary to fulfill the purposes outlined in this
        policy, or as required by law. If you close your account, we may retain certain information to
        comply with legal obligations, resolve disputes, or enforce our agreements.</p>
        
        <h6><strong>5. Data Security</strong></h6>
        <p>We take reasonable measures to protect the personal information we collect and store,
        including physical, technical, and administrative safeguards. However, no data transmission or
        storage system can be guaranteed to be 100% secure. If you have any concerns about the
        security of your personal information, please contact us.</p>
        
        <h6><strong>6. Your Rights</strong></h6>
        <p>You have certain rights regarding your personal information, including the right to access,
        correct, delete, or restrict its processing. You may also have the right to object to processing and
        the right to data portability. To exercise these rights, please contact us using the information
        provided below.</p>
        
        <h6><strong>7. Changes to this Privacy Policy</strong></h6>
        <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be effective
        immediately upon posting the updated policy on our platform. We encourage you to review this
        policy periodically to stay informed about how we collect, use, and protect your information.</p>
        <h6><strong>8. Contact Us</strong></h6>
        <p>If you have any questions, concerns, or requests regarding this Privacy Policy.</p>
      </Container>
    </div>
  );
}

export default Privacy;
