import axios from 'axios';
import Fetch from '../helpers/fetch';
import { BASE_URL } from './config';

const url = `${BASE_URL}/sessions`;
const tripsDayWeekMonthURL = `${BASE_URL}/sessions/trips/number`

const authToken = localStorage.getItem('token')

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${authToken}`
};

const getSessions = async () => {
  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
  });
  console.log("here are all the sessions:", result)
  return result.json();
};


const addSessions = async (body) => {
  const result = await Fetch(`${url}/book`, 'POST', body);
  return result.json();
};

const manageSession = async (id, manage) => {
  const result = await Fetch(`${url}/manage/${id}`, 'PATCH', manage);
  return result.json();
};

const getSessionById = async (id) => {
  const result = await axios.get(`${url}/getSessionById/${id}`);
  return result.data;
};

const updateSession = async (id, updates) => {
  const result = await Fetch(`${url}/${id}`, 'PATCH', updates);
  return result.json();
};

const deleteSession = async (id) => {
  const result = await fetch(`${url}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
  });
  return result.json();
};

const verifyDPOPayment = async (body) => {
  const result = await Fetch(`${url}/verify-dpo-transanction`, 'POST', body);
  return result.json();
};

const requestSpennPayment = async(body) =>{
  const result = await Fetch(`${url}/request-spenn-payment`,'POST', body)

  return result.json()
}

const checkSpennPayment = async(reference)=>{
  const result = await Fetch(`${url}/check-spenn-payment/${reference}`)
  return result.json()
}

const requestMoMoPayment = async(body)=>{
  const result = await Fetch(`${url}/request-momo-payment`,'POST', body)
  return result.json()
}

const checkMoMoPayment = async(reference)=>{
  const result = await Fetch(`${url}/check-momo-payment/${reference}`)
  return result.json()
}

const requestDpoPayment = async(body)=>{
  const result = await Fetch(`${url}/add`,'POST', body)
  return result.json()
}

const tripsDayWeekMonth = async ()=>{
  const response = await axios.get(tripsDayWeekMonthURL, {headers:headers})
  return response.data
}


export {
  getSessions,
  addSessions,
  manageSession,
  updateSession,
  verifyDPOPayment,
  requestSpennPayment,
  checkSpennPayment,
  requestMoMoPayment,
  checkMoMoPayment,
  requestDpoPayment,
  getSessionById,
  deleteSession,
  tripsDayWeekMonth
};
