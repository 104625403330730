import './terms.css';
import React from 'react';

import Container from 'react-bootstrap/Container';

const terms = () => {
  return (
    <div className="terms-base">
      <Container className="terms-body">
        <span className="terms-title">Terms of use</span>
        <span className="terms-title">Contractual Relationship</span>
        These Terms of Use (“Terms”) govern the access or use by you, an
        individual, from within Rwanda, (the “Services”) made available by
        TUZAride a private limited liability company established in Rwanda,
        having its offices in Kigali city-Rwanda. PLEASE READ THESE TERMS
        CAREFULLY BEFORE ACCESSING OR USING THE SERVICES. Your access and use of
        the Services constitutes your agreement to be bound by these Terms,
        which establishes a contractual relationship between you and TUZAride.
        If you do not agree to these Terms, you may not access or use the
        Services. These Terms expressly supersede prior agreements or
        arrangements with you.TUZAride may immediately terminate these Terms or
        any Services with respect to you, or generally cease offering or deny
        access to the Services or any portion thereof, at any time for any
        reason. TUZAride may amend the Terms related to the Services from time
        to time. Amendments will be effective upon TUZAride’s posting of such
        updated Terms at this location or the amended policies or supplemental
        terms on the applicable Service. Your continued access or use of the
        Services after such posting constitutes your consent to be bound by the
        Terms, as amended. Our collection and use of personal information in
        connection with the Services is as provided in TUZAride’s Privacy Policy
        located at www.tuzaride.com TUZAride may provide to a claims processor
        or an insurer any necessary information (including your contact
        information) if there is a complaint, dispute or conflict, which may
        include an accident, involving you and a Third Party Provider (including
        a transportation network company driver) and such information or data is
        necessary to resolve the complaint, dispute or conflict.
        <span className="terms-title">The Services</span>
        Our Services constitute a technology platform that enables users
        (“Users”) to arrange and schedule Personal Driving services(chauffeur)
        Third-Party Providers of such services (“Third-Party
        Providers”).TUZARIDE DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS
        SERVICES, AND TUZARIDE IS NOT A TRANSPORTATION CARRIER. Third-Party
        Providers have sole and complete discretion to offer, accept or reject
        each request for transportation and/or logistics services. It is up to
        the User to decide whether or not to accept a service from any
        Third-Party Provider contacted through the Tuzaride Platform. Each
        service provided constitute a separate agreement between the Third-Party
        Providers and Users.
        <span className="terms-title">License</span>
        Subject to your compliance with these Terms, TUZAride grants you a
        limited, non-exclusive, non-sublicensable, revocable, non-transferable
        license to download, install and use our mobile application (the
        “Application”) or website on your personal device, solely in connection
        with your use of our Services.
        <span className="terms-title">Restrictions</span>
        You shall not (i) license, sublicense, sell, lease, transfer, assign,
        distribute, modify, prepare derivative works based upon, reproduce or
        otherwise exploit or make available to any third party any part of our
        Services; (ii) reverse engineer, decompile, modify, or adapt any part of
        our Services (except as may be permitted by applicable law); (iii)
        “frame”, “mirror”, or “link” to any part of our Services; (iv) launch
        any programs or scripts for the purpose of scraping, indexing,
        surveying, or data mining any part of our Services or unduly burdening
        or hindering the operation and/or performance of any aspect our
        Services; (v) attempt to gain unauthorized access to or impair any
        aspect of our Services or its related systems or networks.
        <span className="terms-title">Provision of the Services.</span>
        You acknowledge that portions of the Services may be made available
        underTUZAride’s various brands or request options associated with
        transportation. The Services may be made available or accessed in
        connection with third party services and content (including advertising)
        that TUZAride does not control. You acknowledge that different terms of
        use and privacy policies may apply to your use of such third party
        services and content.Tuzaride does not endorse such third party services
        and content and in no event shall TuUZAride be responsible or liable for
        any products or services of such third party providers.
        <span className="terms-title">Ownership</span>
        The Services and all rights therein are and shall remain TUZAride’s
        property or the property of TUZAride’s licensors. Neither these Terms
        nor your use of the Services convey or grant to you any rights: (i) in
        or related to the Services except for the limited license granted above;
        or (ii) to use or reference in any manner TUZAride’s company names,
        logos, product and service names, trademarks or services marks or those
        of TUZAride’s licensors. Your Use of the Services User Accounts in order
        to use most aspects of the Services, you must register for and maintain
        an active personal user Services account (“Account”). You must be at
        least 18 years of age to obtain an Account. Account registration
        requires you to submit to TUZAride certain personal information, such as
        your name, address, mobile phone number and age, as well as at least one
        valid payment method (either a credit card or accepted payment partner).
        You agree to maintain accurate, complete, and up-to-date information in
        your Account. Your failure to maintain accurate, complete, and
        up-to-date Account information, including having an invalid or expired
        payment method on file, may result in your inability to access and use
        the Services. You are responsible for all activity that occurs under
        your Account, and you agree to maintain the security and secrecy of your
        Account username and password at all times. Unless otherwise permitted
        by TUZAride in writing, you may only possess one Account. User
        Requirements and Conduct.
        <span className="terms-title">Text messaging</span>
        By creating an Account, you agree that the Services may send you text
        (SMS) messages as part of the normal business operation of your use of
        the Services. You may opt-out of receiving text (SMS) messages from
        TUZAride at any time. You acknowledge that opting out of receiving text
        (SMS) messages may impact your use of the Services.
        <span className="terms-title">Network Access and Devices. </span>
        You are responsible for obtaining the data network access necessary to
        use the Services. Your mobile network’s data and messaging rates and
        fees may apply if you access or use the Services from a wireless-enabled
        device and you shall be responsible for such rates and fees. You are
        responsible for acquiring and updating compatible hardware or devices
        necessary to access and use the Services and Applications and any
        updates thereto. TUZAride does not guarantee that the Services, or any
        portion thereof, will function on any particular hardware or devices. In
        addition, the Services may be subject to malfunctions and delays
        inherent in the use of the Internet and electronic communications.
        <span className="terms-title">Payment</span>
        You understand that use of our Services may result in charges to you to
        you for the services or goods you receive from a Third-Party Provider
        (“Charges”). Charges include Fares and other applicable fees, tolls,
        surcharges, and taxes, including booking and/or processing fees. A
        cancellation fee may be charged if you elect to cancel your request for
        services after requesting a Driver, or if you fail to show up after
        requesting a Driver. You are responsible for reviewing the applicable
        fare info within the TUZAride app and shall be responsible for all
        Charges incurred under your User account regardless of your awareness of
        such Charges or the amounts thereof.The Charges shall be collected by
        the Third-Party Provider or TUZAride at the end of the Ride. TUZAride
        shall provide a receipt of the Charges. All Charges are non-refundable.
        You may choose to pay the Charges by one of the following methods: (1)
        Cash Payment collected by the Third-Party Provider; (2) Cashless Payment
        collected by TUZAride through a payment processor (“Payment Processor”).
        For cash payments the total charge is rounded up to the next hundred
        RWF. For cashless payments TUZAride will also collect the Fare on behalf
        of the Third-Party Provider who provided the Driving services. The
        Payment Processor may either be TUZAride or any of its affiliates or
        partners or unrelated third parties. You agree and undertake to share
        relevant payment details with the Payment Processor and authorize the
        Payment Processor to debit or credit the bank account or mobile money
        wallet associated with your payment details. Your authorization will
        remain in effect as long as you maintain an Account with TUZAride. In
        the event you delete your payment details with the Payment Processor or
        if you delete your User Account, the Payment Processor will not process
        any further transactions initiated by you on our Platform. TUZAride will
        not be responsible for any errors by the Payment Processor in any
        manner. Your authorization under this clause is subject to any other
        terms and conditions of the Payment Processor.
        <span className="terms-title">Disclaimer</span>
        YOU ACKNOWLEDGE THAT TUZARIDE DOES NOT PROVIDE TRANSPORTATION AND/OR
        LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER. Our Services
        are provided “as is” and “as available.” All conditions, representations
        and warranties, whether expressed, implied, statutory, not expressly set
        out in these Terms, or otherwise, including any implied warranty for a
        particular purpose and non-infringement of third-party rights, are
        hereby excluded and disclaimed to the highest and maximum extent. We are
        not liable or responsible for any vehicle conditions, road accidents,
        personal driver’s mistakes on the road, traffic fines, safety of
        properties or loss of money/belongings on board during and after the
        driver’s service. We make no representation, warranty, or guarantee
        regarding the reliability, timeliness, quality, security, or
        availability of our Services, or any Services or goods requested through
        the use of our Services, or that our Services will be uninterrupted or
        error-free. We are not responsible for the conduct of any Users of our
        Services or Third-Party Providers. We are not responsible for any loss
        or damage of personal belongings left in a Third-Party Provider’s
        vehicle.
        <span className="terms-title">Indemnity</span>
        You agree to indemnify and hold TUZAride and its officers, directors,
        employees and agents harmless from any and all claims, demands, losses,
        liabilities, and expenses (including attorneys’ fees) arising out of or
        in connection with: (i) your use of the Services or services obtained
        through your use of the Services; (ii) your breach or violation of any
        of these Terms; (iii) TUZAride’s use of your User Content; or (iv) your
        violation of the rights of any third party, including Third Party
        Providers.
        <span className="terms-title">Governing Law</span>
        You agree that this Agreement and your use of TUZAride Platform and its
        content are governed exclusively by the laws of the Republic of Rwanda.
        Any claim relating to this Agreement, or your use of TUZAride Platform
        must be settled amicably, failure of which shall be commenced in such a
        Court within Three (3) months period after the claim or cause of action
        arises, after which time the claim or cause of action shall be barred.
        <span className="terms-title">General provisions</span>
        If any provision of this Agreement is found to be void, or for any
        reason unenforceable, then that provision shall be deemed to be
        severable from the rest of the Agreement and shall not affect the
        validity and enforceability of any remaining provisions.
      </Container>
    </div>
  );
};

export default terms;
