/* eslint-disable no-undef */
import './forms.css';
import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Spinner, Alert, Card, Table,Container, InputGroup } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { differenceInDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowRight, FaEnvelope,FaEye, FaEyeSlash } from 'react-icons/fa';
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';
import {
  addDrivers,
  updateDrivers,
  deleteDrivers,
  addSessions,
  getUserProfile, 
  login
} from '../../services';

import { setCurrentUser } from '../../redux/actions';
import { handlePrice } from '../../utils';
import { mapCoordinates, tripOptions } from '../../constants/session';
import PaymentGateway from '../../Pages/PaymentGateway/PaymentGateway';



function BookingForm() {

  const { currentUser } = useSelector((state) => state.authentication);
  const navigate = useNavigate();
  const currentUserName = currentUser ? currentUser.name : '';
  const currentUserTel = currentUser? currentUser.tel : '';

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState(0);
  const [autocompleteDest, setAutocompleteDest] = useState(null);
  const [destination, setDestination] = useState('');
  const [autocompletePickup, setAutocompletePickup] = useState(null);
  const [pickup, setPickup] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedTrip, setSelectedTrip] = useState(tripOptions[0].name);
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState({
    clientNames: currentUserName,
    pickupLocation: '',
    destination: '',
    phoneNumber: currentUserTel,
  });

  const [showErrorMessage, setShowErrorMesssage] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null)
  const [errorPhone, setErrorPhone] = useState(null)

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();

  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const isMultipleTrips = selectedTrip === tripOptions[1].name;

  const areInputsValidated = () => {
    const result = true;
    if (isMultipleTrips && !startDate) {
      setErrors({ ...errors, startDate: 'Start Date is required' });
      return false;
    }

    if (isMultipleTrips && !endDate) {
      setErrors({ ...errors, endDate: 'End Date is required' });
      return false;
    }
    return result;
  };

  let tripPrice = price;
  if (isMultipleTrips && startDate && endDate) {
    const numberOfDays = differenceInDays(endDate, startDate) + 1;
    if (numberOfDays > 1) {
      tripPrice *= numberOfDays;
    }
  }

  const handleBooking = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else if (areInputsValidated()) {
      setLoading(true);
      const payload = {
        ...booking,
        pickupLocation: originRef.current.value,
        destination: destinationRef.current.value,
        distance,
        price: tripPrice,
        tripType: selectedTrip,
        startDate: isMultipleTrips ? startDate : '',
        endDate: isMultipleTrips ? endDate : '',
      };
      addSessions(payload).then((res) => {
        if (res.status === 201) {
          setBooking({
            clientNames: currentUserName,
            pickupLocation: '',
            destination: '',
            phoneNumber: currentUserTel,
          });
          setLoading(false);
          // setShow(false);
          localStorage.setItem('currentBooking', JSON.stringify(res.data));
          // console.log({currentBooking: JSON.parse(localStorage.getItem('currentBooking'))})
          navigate('/payment-gateway')
          
        } else {
          setLoading(false);
          setShowErrorMesssage(true);
        }
      });
    }
  };

  const handleInputBooking = (e) => {
    const { id, value } = e.target;
    setBooking({ ...booking, [id]: value });
    const nameRegex = /^[a-zA-Z\s]+$/;
    const phoneRegex = /^\+2507[8293]\d{7}$/;

    if(id === 'clientNames'){
      if(!nameRegex.test(value)){
        setError('invalid name')
      }else{
        setError(null)
      }
    }
    
    if(id ==="phoneNumber"){
      if(!phoneRegex.test(value)){
        setErrorPhone("phone number starts with +250 and must be 12 digits ")
      }else{
        setErrorPhone(null)
      }
    }
  };

  const calculateRoute = async (pickupAddress, destinationAddress) => {
    if (pickupAddress && destinationAddress) {
      const directionService = new google.maps.DirectionsService();
      const results = await directionService.route({
        origin: pickupAddress,
        destination: destinationAddress,
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(results);
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration.text);
      const costInRwf = handlePrice(results.routes[0].legs[0].distance.text);
      setPrice(Math.trunc(costInRwf));
    }
  };

  const onLoadPickup = (autocomplete) => {
    setAutocompletePickup(autocomplete);
  };

  const onChangePickup = () => {
    if (autocompletePickup !== null) {
      const pickUpaddress = autocompletePickup.getPlace().formatted_address;
      setPickup(autocompletePickup.getPlace().formatted_address);
      calculateRoute(pickUpaddress, destination);
    } else {
      // console.log('Autocomplete is not loaded yet!');
    }
  };

  const onLoadDestination = (autocomplete) => {
    setAutocompleteDest(autocomplete);
  };

  const onChangeDestination = () => {
    if (autocompleteDest !== null) {
      const destinationAddress = autocompleteDest.getPlace().formatted_address;
      setDestination(destinationAddress);
      calculateRoute(pickup, destinationAddress);
    } else {
      // console.log('Autocomplete is not loaded yet!');
    }
  };

  const centerLoading = (
    <div style={{ margin: '20px auto', display: 'block' }}>
      <Spinner animation="grow" variant="primary" />
    </div>
  );

  return (
    <div className="container">
      <div className='book-form-with-map'>
        <Form
          className="booking-form"
          noValidate
          validated={validated}
          onSubmit={handleBooking}
        >
          <Form.Group>
            <Form.Label>Names</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your names"
              id="clientNames"
              value={booking.clientNames}
              onChange={handleInputBooking}
              required
              disabled = {localStorage.getItem('token')&&booking.clientNames !== ""}
            />
            <Form.Control.Feedback type="invalid">
              Please provide your name
            </Form.Control.Feedback>
          </Form.Group>
          {error && <p style={{color: 'red'}}>{error}</p>}
          <Form.Group className="top-margin">
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your phone number"
              id="phoneNumber"
              value={booking.phoneNumber}
              onChange={handleInputBooking}
              required
              disabled = {localStorage.getItem('token') && booking.phoneNumber !== ""}
            />
            <Form.Control.Feedback type="invalid">
              Please provide your phone number
            </Form.Control.Feedback>
          </Form.Group>
          {errorPhone && <p style={{color: 'red'}}>{errorPhone}</p>}
          {!isLoaded ? (
            centerLoading
          ) : (
            <div>
              <Form.Group className="top-margin">
                <Form.Label>Pickup location</Form.Label>
                <Autocomplete
                  onLoad={onLoadPickup}
                  onPlaceChanged={onChangePickup}
                >
                  <Form.Control
                    id="pickupLocation"
                    type="text"
                    placeholder="Enter your location"
                    ref={originRef}
                    required
                  />
                </Autocomplete>
              </Form.Group>
              <Form.Group className="top-margin">
                <Form.Label>Destination</Form.Label>
                <Autocomplete
                  onLoad={onLoadDestination}
                  onPlaceChanged={onChangeDestination}
                >
                  <Form.Control
                    id="destination"
                    type="text"
                    placeholder="Enter your destination"
                    ref={destinationRef}
                    required
                  />
                </Autocomplete>
              </Form.Group>
              <div className="select-trip-group">
                <div
                  style={{
                    minWidth: 150,
                    width: isMultipleTrips ? 'auto' : '100%',
                  }}
                >
                  <Form.Group controlId="Select trip">
                    <Form.Label>Select Trip</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedTrip}
                      onChange={(e) => setSelectedTrip(e.target.value)}
                    >
                      {tripOptions.map((trip) => (
                        <option value={trip.value} key={trip.name}>
                          {trip.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <Form.Group
                  className="start-date-field"
                  style={{ display: isMultipleTrips ? 'block' : 'none' }}
                >
                  <Form.Label>Start date</Form.Label>
                  <DatePicker
                    placeholderText="Start date"
                    selected={startDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeSelect
                    onChange={(date) => {
                      setErrors({ ...errors, startDate: null });
                      setStartDate(date);
                    }}
                    calendarClassName="calendar"
                    className={[
                      'calendar-style',
                      errors.startDate ? 'invalid' : '',
                    ].join(' ')}
                    minDate={new Date()}
                  />
                  {errors.startDate && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      {errors.startDate}
                    </div>
                  )}
                </Form.Group>
                <Form.Group
                  className="end-date-field"
                  style={{ display: isMultipleTrips ? 'block' : 'none' }}
                >
                  <Form.Label>End date</Form.Label>
                  <DatePicker
                    placeholderText="End date"
                    selected={endDate}
                    selectsEnd
                    startDate={startDate}
                    minDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeSelect
                    onChange={(date) => {
                      setErrors({ ...errors, endDate: null });
                      setEndDate(date);
                    }}
                    calendarClassName="calendar"
                    className={[
                      'calendar-style',
                      errors.endDate ? 'invalid' : '',
                    ].join(' ')}
                  />
                  {errors.endDate && (
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      {errors.endDate}
                    </div>
                  )}
                </Form.Group>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: '0.9rem 0 0' }}>Distance: {distance || 0}</p>
                <p style={{ margin: '0.9rem 0 0' }}>Duration: {duration || 0}</p>
                {distance && (
                  <p style={{ margin: '0.9rem 0 0' }}>
                    Price: {`${tripPrice} Rwf`}
                  </p>
                )}
              </div>
            </div>
          )}

          {loading === false ? (
            <Button 
              className="top-margin"
              variant="primary"
              type="submit"
              disabled={!pickup || !destination || !!error || !!errorPhone ||!booking.clientNames||!booking.phoneNumber}
            >
              Book
            </Button>
          ) : (
            centerLoading
          )}
          <Form.Text
            aria-disabled={showErrorMessage}
            value="Something went wrong"
          />
        </Form>

        {!isLoaded ? (
          <div
            style={{ width: '50%', display: 'flex', justifyContent: 'center' }}
          >
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
          <GoogleMap
            zoom={15}
            center={mapCoordinates}
            mapContainerClassName="map-container"
            onLoad={(newMap) => setMap(newMap)}
          >
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        )}
      </div>
    </div>
  );
}



function 
LoginForm() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const location = useLocation()
  const [error, setError] = useState(null)
  const handleInput = (e) => {
    const { id, value } = e.target;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if(id === 'email' && !emailRegex.test(value)){
      setError("invalid email format")
    }
    else{
      setError(null)
    }
    setFormData({ ...formData, [id]: value });
  };
  return (
    <Form>
      <Form.Group className="mt-3 mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          value={formData.email}
          onChange={handleInput}
          type="email"
          id="email"
          placeholder="Enter your email"
        />
      </Form.Group>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          onChange={handleInput}
          value={formData.password}
          type="password"
          id="password"
          placeholder="Enter your password"
        />
      </Form.Group>

      <Button disabled={!!error}
        onClick={(e) => {
          e.preventDefault();
          login(formData)
            .then((res) => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              if (res.status === 200) {
                localStorage.setItem('token', res.data.token);
                
                getUserProfile(res.data.token).then((profileRes) => {
                    localStorage.setItem('currentUser', JSON.stringify(profileRes.data));
                    if (profileRes.status === 200) {
                        dispatch(setCurrentUser(profileRes.data));
                        const fromDeleteAccount = location.state && location.state.fromDeleteAccount ? true : false;
                        if (profileRes.data.role === 'admin') {
                            navigate('/admin');
                        } else if (fromDeleteAccount) {
                            navigate('/cancel-account');
                        } else {
                            navigate('/hire-a-driver');
                        }
                    }
                });
            }
             else {
                setShowAlert(true);
              }
            })
            .catch((err) => {
              console.log('error:', err.message);
            });
        }}
        variant="primary"
        type="submit"
        style={{ marginTop: '.6rem' }}
      >
        <FaArrowRight /> Login
      </Button>
      {showAlert ? (
        <Alert
          dismissible
          onClose={() => setShowAlert(false)}
          style={{ marginTop: '.6rem' }}
          variant="danger"
        >
          Something went wrong
        </Alert>
      ) : (
        <div />
      )}
    </Form>
  );
}

function ContactForm() {
  return (
    <Form>
      <Form.Group className="mt-3 mb-3">
        <Form.Label>Names</Form.Label>
        <Form.Control type="text" placeholder="Enter your names" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone number</Form.Label>
        <Form.Control type="text" placeholder="Enter your phone number" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control type="text" placeholder="Enter your message here" />
      </Form.Group>
      <Form.Group>
        <Button variant="primary" type="submit" style={{ marginTop: '.6rem' }}>
          <FaEnvelope /> Send
        </Button>
      </Form.Group>
    </Form>
  );
}

function DriversForm(props) {
  const [data, setData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { currentDriver } = useSelector((state) => state.drivers);
  const [driversFormSuccessAlert, openDriversFormSuccessAlert] =
    useState(false);
  const [driversFormErrorAlert, openDriversFormErrorAlert] = useState(false);
  const handleInput = (e) => {
    // console.table(data)
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  };
  const handleFile = (e) => {
    const { id, files } = e.target;
    setData({ ...data, [id]: files[0] });
  };
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.table(data)
    const { action, id } = props;
    if (action === 'add') {
      addDrivers(data).then((res) => {
        // console.log("============================================>datasssssssssssssssssssssss", data)
        if (res.status === 201) {
          setData({});
          // console.log('Progress')
          setUrl('');
          openDriversFormSuccessAlert(true);
        } else {
          openDriversFormErrorAlert(true);
        }
      });
    } else {
      updateDrivers(data, id).then((res) => {
        // console.log('datas======================================================>',data)
        if (res.status === 200) {
          setData({});
          setUrl('');
          openDriversFormSuccessAlert(true);
        } else {
          openDriversFormErrorAlert(true);
        }
      });
    }
  };

  const { action, setShow } = props;

  return (
    <Form style={{ width: '30vw', marginTop: '1.6rem' }}>
      <Form.Group>
        <Form.Label>Names</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter their names"
          id="name"
          value={data.name}
          onChange={handleInput}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Phone number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your phone number"
          id="phoneNumber"
          value={data.phoneNumber}
          onChange={handleInput}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Bio</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter a short bio"
          id="bio"
          value={data.bio}
          onChange={handleInput}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          id="email"
          value={data.email}
          onChange={handleInput}
        />
      </Form.Group>

      <Form.Group>
  <Form.Label>Password</Form.Label>
  <div style={{ position: 'relative' }}>
    <Form.Control
      type={showPassword ? 'text' : 'password'}
      placeholder="Enter password"
      id="password"
      value={data.password}
      onChange={handleInput}
      style={{ paddingRight: '40px' }} // Create space for the icon
    />
    <span
      style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer'
      }}
      onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </span>
  </div>
</Form.Group>



  

      <Form.Group>
        <Form.Label>Image</Form.Label>
        <Form.Control
          type="file"
          id="image"
          placeholder="Select a file"
          onChange={handleFile}
        />
      </Form.Group>
      <Form.Group>
        <Button
          variant="primary"
          type="submit"
          style={{ marginTop: '.6rem' }}
          onClick={handleSubmit}
        >
          <FaEnvelope /> set up
        </Button>
        {action === 'update' && (
          <Button
            onClick={() => {
              deleteDrivers(currentDriver)
                .then((res) => {
                  if (res.status === 200) {
                    setShow(false);
                  }
                })
                .catch((err) => {
                  // console.log('deleted', err);
                });
            }}
            variant="danger"
            style={{ marginTop: '.6rem', marginLeft: '.2rem' }}
          >
            {' '}
            Delete{' '}
          </Button>
        )}
      </Form.Group>

      <Form.Group style={{ marginTop: '.6rem' }}>
        {driversFormErrorAlert && (
          <Alert
            dismissible
            onClose={() => openDriversFormErrorAlert(false)}
            variant="danger"
          >
            Couldn&apos;t add a driver
          </Alert>
        )}
        {driversFormSuccessAlert && (
          <Alert
            dismissible
            onClose={() => openDriversFormSuccessAlert(false)}
            variant="success"
          >
            The driver was added
          </Alert>
        )}
      </Form.Group>
    </Form>
  );
}

const DriverFormAfterPayment = () => {
  const currentBooking = JSON.parse(localStorage.getItem('currentBooking'));
  const [stickers, setStickers] = useState([]);

  useEffect(() => {
    const newStickers = [];
    for (let i = 0; i < 30; i++) {
      newStickers.push({
        id: i,
        left: `${Math.random() * 100}vw`,
        animationDelay: `${Math.random() * 2}s`
      });
    }
    setStickers(newStickers);
  
    const timeoutId = setTimeout(() => {
      setStickers([]);
    }, 4000);
  
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Container className="app-container">
      {stickers.map(sticker => (
        <div
          key={sticker.id}
          className="sticker"
          style={{ left: sticker.left, animationDelay: sticker.animationDelay }}
        >
          👏
        </div>
      ))}
      <Alert variant="success" className="app-alert">
        Successful paid
      </Alert>
      <div className='app-card-container'>
      <Card className="app-card">
        <Card.Header className="card-header">Driver Arriving in few Minutes</Card.Header>
        <Card.Body>
          <div className="user-info">
            <img className="user-image" src={currentBooking.driver.image} alt="Driver" />
            <h3 className="user-name">{currentBooking.driver.name}</h3>
          </div>
          <Table responsive className="info-table">
            <tbody>
              <tr>
                <td>Pick up location:</td>
                <td>{currentBooking.pickupLocation}</td>
              </tr>
              <tr>
                <td>Destination location:</td>
                <td>{currentBooking.destination}</td>
              </tr>
              <tr>
                <td>Cost:</td>
                <td>{currentBooking.price}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      </div>
    </Container>
  );
};


export { BookingForm, LoginForm, ContactForm, DriversForm, DriverFormAfterPayment };
