import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { tripsDayWeekMonth, getDrivers, numberOfDrivers, numberOfUsers, findFeedbackforadriver } from '../../services';
import { Card, Container, Row, Col, Table, Button, Modal} from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import StarRatings from 'react-star-ratings';

function VisualRepresentation() {
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [users, setUsers] = useState(0);
  const [drivers, setDrivers] = useState(0);
  const [allDrivers, setAllDrivers] = useState([]);
  const [driverReviews, setDriverReviews] = useState(null);
  const [showModal, setShowModal] = useState(false);


  const fetchData = async () => {
    const response = await tripsDayWeekMonth();
    const numberOfUsersOfOurSystem = await numberOfUsers();
    const numberOfDriversOfOurSystem = await numberOfDrivers();
    const allDriversResponse = await getDrivers();

    const users = numberOfUsersOfOurSystem.data;
    const drivers = numberOfDriversOfOurSystem.data;
    const weeklyTrips = Object.entries(response.data.tripsPerDayOfWeek)
      .filter(([day, trips]) => day !== 'undefined')
      .map(([day, trips]) => ({ name: day, Trips: trips }));

    const monthlyTrips = Object.entries(response.data.tripsPerMonth)
      .filter(([month, trips]) => month !== 'undefined')
      .map(([month, trips]) => ({ name: month, Trips: trips }));

    setUsers(users);
    setDrivers(drivers);
    setAllDrivers(allDriversResponse.data);

    return { weeklyTrips, monthlyTrips };
  };

  const viewDriverReviews = async (id) => {
    const response = await findFeedbackforadriver(id);
    setDriverReviews(response.data);
    setShowModal(true);
  };

  const backButtonHandler = () => {
    setDriverReviews(null);
    setShowModal(false);
  };

  useEffect(() => {
    fetchData().then(({ weeklyTrips, monthlyTrips }) => {
      setWeeklyData(weeklyTrips);
      setMonthlyData(monthlyTrips);
    });
  }, []);

  return (
    <Container className='mt-4' fluid>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Total number of users</Card.Title>
              <Card.Text>{users}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Total number of drivers</Card.Title>
              <Card.Text>{drivers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Trips Per Day of the Week</Card.Title>
              <LineChart width={500} height={300} data={weeklyData}>
                <Line type="monotone" dataKey="Trips" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
              </LineChart>
            </Card.Body>
          </Card>
        </Col>
  
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Trips Per Month</Card.Title>
              <LineChart width={500} height={300} data={monthlyData}>
                <Line type="monotone" dataKey="Trips" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
              </LineChart>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Feedback Per Driver</Card.Title>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Bio</th>
                    <th>Phone Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allDrivers.map(driver => (
                    <tr key={driver._id}>
                      <td>{driver.name}</td>
                      <td><img src={driver.image} alt={driver.name} style={{width: '50px', height: '50px', borderRadius: '50%'}}/></td>
                      <td>{driver.bio}</td>
                      <td>{driver.phoneNumber}</td>
                      <td><Button variant="link" onClick={() => viewDriverReviews(driver._id)}><FaEye/></Button></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  
      <Modal show={showModal} onHide={backButtonHandler}>
        <Modal.Header closeButton>
          <Modal.Title>Driver Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
              <th>client names</th>
              <th>client email</th>
                <th>review</th>
                <th>rate</th>
               
              </tr>
            </thead>
            <tbody>
              {driverReviews !== null 
              ? driverReviews.length > 0 
                ? driverReviews.map(review => (
                    <tr key={review._id}>
                      <td>{review.sessionId.clientNames}</td>
                      <td>{review.userEmail}</td>
                      <td>{review.review}</td>
                      <td>
                        <StarRatings
                          rating={review.rate}
                          starRatedColor="blue"
                          numberOfStars={5}
                          name='rating'
                          starDimension="20px"
                          starSpacing="2px"
                        />
                      </td>
                    </tr>
                  ))
                : <tr><td colSpan="5">No reviews for this driver.</td></tr>
              : null}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </Container>
  );
  
   
}

export default VisualRepresentation;
