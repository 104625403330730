/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ProfileCard as Card } from '../../components/Card';
import { CustomModal } from '../../components/Modal';
import { getDrivers } from '../../services';
import './index.css';

const rawData = [
  {
    el: 1,
    image: '',
  },
  { el: 2, image: '' },
];

function HireADriver() {
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState('profile');
  const [data, setData] = useState(rawData);
  const [selected, setSelected] = useState({
    name: '',
    image: '',
    bio: '',
    _id: '',
  });

  useEffect(() => {
    getDrivers().then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      <CustomModal
        header="More about the driver"
        modalType={modalType}
        title={selected.name}
        desc={selected.bio}
        image={selected.image}
        show={show}
        setShow={setShow}
        setModalType={setModalType}
        driver={selected._id}
      />
      <div id="hire-a-driver">
        <Container>
          <div className="drivers-title primaryColor">Available Drivers</div>
        </Container>
        <div className="profiles">
          <Container>
            {data && data.length > 0 && (
              <Row>
                {data.map((el, idx) => {
                  const elData = {
                    image: el.image,
                    title: el.name,
                    desc: el.bio,
                    action: 'Hire me',
                    handlerType: 'redirect',
                    isAvailable: el.isAvailable,
                    _id: el._id,
                  };
                  return (
                    <Col key={idx} md={4} sm={6} xs={6}>
                      <div className="wrap-container">
                        <Card
                          className="wrap-card"
                          data={elData}
                          handlerType="_redirect"
                          handler={() => {
                            setShow(true);
                            setSelected(el);
                          }}
                          key={el}
                          pathname="/profile"
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default HireADriver;
