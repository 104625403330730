import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import airportServices from '../../assets/images/Airport-services.jpg';
import schoolPickup from '../../assets/images/Patients-Medical-appointments.jpg';
import hospitalappointment from '../../assets/images/06Navathe-articleLarge.webp';
import corporateServices from '../../assets/images/CORPORATE-SERVICES.webp';
import alcoholConsumption from '../../assets/images/Party-doers.jpg';
import touristTravelers from '../../assets/images/main-page-photo.png';
import customersCover from '../../assets/images/side-portrait-happy-young-african-260nw-461589331.png';
import './customers.css';

const customers = [
  {
    img: airportServices,
    title: 'Airport Services',
  },
  {
    img: schoolPickup,
    title: 'School pickup',
  },
  {
    img: hospitalappointment,
    title: 'Hospital appointment',
  },
  {
    img: corporateServices,
    title: 'Corporate Services',
  },
  {
    img: alcoholConsumption,
    title: 'Alcohol consumption',
  },
  {
    img: touristTravelers,
    title: 'Holidaty/Tourist travelers',
  },
];

function Customers() {
  return (
    <>
      <section
        className="hero-wrap"
        style={{
          backgroundImage: `url(${customersCover})`,
          backgroundPosition: '50% 0px',
        }}
      >
        <div
          className="container"
          style={{ position: 'relative', height: '100%' }}
        >
          <div
            className="col-lg-8 ftco-animate fadeInUp ftco-animated"
            style={{ bottom: 50 }}
          >
            <div className="text w-100">
              <span className="customers-title">Our Customers</span>
            </div>
          </div>
        </div>
      </section>
      <Container className="about-body">
        <div className="about-title">WHO ARE OUR CUSTOMERS?</div>
        <p>
          Our targeted customers vary by interests, desires, behavior and they
          could be every person who owns a vehicle but not able to drive due to
          different reasons;
        </p>
        <ul>
          <li>
            Airport services: Air passengers who need a driver to drop them off
            or pick them up from the airport
          </li>
          <li>
            School pick up and drop off: Busy parents who need a driver to pick
            up and drop off the children at school
          </li>
          <li>
            Alcohol consumption: People who need a driver because they are not
            in a place to drive, this may be due to consumption of alcohol.
          </li>
          <li>
            Hospital appointments: patient or people who have hospital
            appointment or other health conditions and they are not able to
            drive.
          </li>
          <li>
            Long distance drive: People who are unable or not willing to drive a
            long distance.
          </li>
          <li>
            Long term drivers: Any other person who may need driving service for
            a long period of time
          </li>
          <li>Corporate services: Officials in need of driving services</li>
        </ul>
        <Row>
          {customers.map((item) => (
            <Col md={4} sm={6} xs={6} key={item.title}>
              <div className="car-wrap">
                <img src={item.img} alt="" className="customer-img" />
                <div className="text">
                  <h6 className="mb-0">{item.title}</h6>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Customers;
